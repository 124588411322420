<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Conditions Générales d'Utilisation</h1>
      <div class="content">
        <h2>Article 1 – Objet</h2>
        <p>
          Les présentes Conditions Générales d'Utilisation (CGU) ont pour objet
          de définir les conditions d'accès et d'utilisation du site
          <strong>Saint Cyr Herbignac Football</strong>, un site dédié à la
          gestion et au suivi de l’activité du club de football amateur
          <strong>Saint Cyr Herbignac Football</strong>. Le site est accessible
          à tous les utilisateurs, bien que certaines fonctionnalités soient
          réservées à l'administration.
        </p>
        <p>
          En accédant et en naviguant sur le site, vous acceptez les présentes
          CGU dans leur intégralité.
        </p>

        <h2>Article 2 – Accès au site</h2>
        <p>
          L’accès au site est gratuit pour tous les utilisateurs. Le site permet
          de consulter des informations relatives aux joueurs, équipes,
          catégories, calendriers et résultats.
        </p>
        <p>
          Les connexions et la gestion des données sont réservées à
          l'administration du site, notamment pour l’ajout, la modification ou
          la suppression des informations relatives aux joueurs (noms, prénoms,
          catégories).
        </p>

        <h2>Article 3 – Gestion des données personnelles</h2>
        <p>
          Les informations collectées sur les joueurs, à savoir les noms,
          prénoms et catégories d’âge, sont exclusivement destinées à la gestion
          interne du club de football amateur et à la publication des équipes
          sur le site. Aucune autre donnée personnelle n’est collectée sans
          consentement préalable.
        </p>
        <p>
          Conformément au Règlement Général sur la Protection des Données
          (RGPD), vous avez le droit d’accéder, de rectifier ou de supprimer les
          données vous concernant. Pour exercer ce droit, vous pouvez contacter
          <strong>herbignacfoot.site@gmail.com</strong>.
        </p>

        <h2>Article 4 – Utilisation des informations</h2>
        <p>
          Les informations présentes sur le site, y compris celles concernant
          les joueurs, sont mises à disposition à des fins d'information et de
          suivi de l'activité du club.
        </p>
        <p>
          Il est interdit d’utiliser les informations du site à des fins
          commerciales, de reproduction, ou pour toute activité non conforme aux
          objectifs de la plateforme.
        </p>

        <h2>Article 5 – Sécurité des données</h2>
        <p>
          Le site met en place les mesures nécessaires pour assurer la
          protection des données personnelles des joueurs inscrits. Toutefois,
          les utilisateurs sont informés que la transmission des données sur
          Internet n’est jamais totalement sécurisée. Le site ne peut être tenu
          responsable en cas de perte ou de vol de données hors de son contrôle.
        </p>

        <h2>Article 6 – Responsabilité</h2>
        <p>
          Le club et les administrateurs du site mettent tout en œuvre pour
          garantir la mise à jour et l’exactitude des informations présentes.
          Cependant, ils ne peuvent être tenus responsables des erreurs ou
          omissions, ni des conséquences directes ou indirectes pouvant résulter
          de l’utilisation des informations disponibles sur le site.
        </p>
        <p>
          Le site peut contenir des liens vers des sites tiers. Le club n’est
          pas responsable du contenu de ces sites externes.
        </p>

        <h2>Article 7 – Propriété intellectuelle</h2>
        <p>
          Tous les éléments du site (textes, images, graphismes, logos, vidéos)
          sont protégés par des droits de propriété intellectuelle. Toute
          reproduction ou représentation, en tout ou en partie, sans
          l’autorisation expresse du club de football est interdite.
        </p>

        <h2>Article 8 – Modifications des CGU</h2>
        <p>
          Le club de football amateur se réserve le droit de modifier les
          présentes CGU à tout moment, notamment pour se conformer à de
          nouvelles réglementations ou pour améliorer l'utilisation du site. Les
          utilisateurs sont invités à consulter régulièrement cette page pour
          prendre connaissance des éventuelles modifications.
        </p>

        <h2>Article 9 – Loi applicable et juridiction compétente</h2>
        <p>
          Les présentes CGU sont régies par le droit français. En cas de litige
          relatif à l’interprétation ou à l'exécution des présentes, les
          tribunaux français seront seuls compétents.
        </p>

        <p class="has-text-right">
          <strong>Date de la dernière mise à jour : 01/01/2024</strong>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CGU",
};
</script>

<style>
.container {
  margin-top: 20px;
}
</style>
