<!-- <template>
  <div class="boutique">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <p class="title">Boutique</p>
          </div>
        </div>
      </section>
    </div>
    <div class="boutique-body">
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="columns is-multiline liste">
            <div
              class="column is-one-third"
              v-for="element in elements"
              :key="element"
            >
              <BoutiqueComponent
                :element="element"
                @addToBasket="ajoutPanier"
              />
            </div>
          </div>
        </div>
        <div class="column is-one-third is-narrow">
          <PanierComponent :panier="panier" @remove="removeFromPanier" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoutiqueComponent from "../components/boutique/BoutiqueComponent.vue";
import PanierComponent from "../components/boutique/PanierComponent.vue";
export default {
  name: "BoutiqueView",
  components: { BoutiqueComponent, PanierComponent },
  data() {
    return {
      elements: {
        element1: {
          id: 1,
          prixAdulte: 45,
          prixEnfant: 26,
          type: "Survêtement",
          name: "CALCITO",
          img: require("@/assets/boutique/article1.png"),
        },
        element2: {
          id: 2,
          prixAdulte: 45,
          prixEnfant: 26,
          type: "Survêtement",
          name: "CALCITO",
          img: require("@/assets/boutique/article2.png"),
        },
        element3: {
          id: 3,
          prixAdulte: 45,
          prixEnfant: 26,
          type: "Survêtement",
          name: "CALCITO",
          img: require("@/assets/boutique/article3.png"),
        },
        element4: {
          id: 4,
          prixAdulte: 45,
          prixEnfant: 26,
          type: "Survêtement",
          name: "CALCITO",
          img: require("@/assets/boutique/article4.png"),
        },
        element5: {
          id: 5,
          prixAdulte: 45,
          prixEnfant: 26,
          type: "Survêtement",
          name: "CALCITO",
          img: require("@/assets/boutique/article5.png"),
        },
        element6: {
          id: 6,
          prixAdulte: 45,
          prixEnfant: 26,
          type: "Survêtement",
          name: "CALCITO",
          img: require("@/assets/boutique/article6.png"),
        },
      },
      panier: [],
    };
  },
  methods: {
    ajoutPanier(item) {
      this.panier.push(item);
    },
    removeFromPanier(item) {
      this.panier = this.panier.filter(
        (data) => data.element.img != item.element.img
      );
    },
  },
};
</script>

<style lang="scss" scoped>
//hero part

.boutique {
  .hero {
    text-align: center;
    p {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/boutique2.jpg");
    }
    .title {
      color: #000000;
    }
  }
  .boutique-body {
    margin-bottom: 20px;
    height: 100%;
  }

  .liste {
    width: 100%;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .panier-container {
    //position: sticky;
  }
}
</style> -->
<template>
  <div class="boutique">
    <div class="content">
      <h3>Prochainement</h3>
      <figure>
        <img src="@/assets/illustrations/maintenance.png" alt="maintenance" />
      </figure>
    </div>
  </div>
</template>

<style lang="scss">
.boutique {
  height: 100%;

  .content {
    h3 {
      padding-top: 70px;
      text-align: center;
    }
    figure {
      margin: 0;
      img {
        width: 50%;
      }
    }
  }
}
</style>
