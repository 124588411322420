<template>
  <div>
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter un article
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Type</th>
          <th>Prix Adulte</th>
          <th>Prix Enfant</th>
          <th>Image</th>
          <th>Modification</th>
          <th>Suppression</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="element in elements" :key="element.id">
          <td>{{ element.name }}</td>
          <td>{{ element.type }}</td>
          <td>
            {{ element.prixAdulte }}
          </td>
          <td>{{ element.prixEnfant }}</td>
          <td v-if="element.img != null">
            <figure class="image is-3by5">
              <img :src="element.img" alt="Image de l'article" />
            </figure>
          </td>

          <td>
            <button class="button is-warning" @click="lauchUpdate(element.id)">
              Modification
            </button>
          </td>
          <td>
            <button
              class="button is-danger is-outlined"
              @click="lauchDelete(element.id)"
            >
              Suppression
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="closeUpdate"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="elementName != null">
            {{ elementName }}
          </p>
          <p class="modal-card-title" v-if="elementName == null">
            Ajouter un article
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeUpdate"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="field">
                  <label class="label">Nom</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Nom"
                      v-model="name"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Type</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="email"
                      id="email"
                      v-model="type"
                      placeholder="Type"
                    />
                  </div>
                </div>

                <div class="columns">
                  <div class="field column is-5">
                    <label class="label">Prix adulte</label>
                    <div class="control">
                      <input
                        class="input"
                        type="number"
                        step="0.01"
                        v-model="prixAdulte"
                      />
                    </div>
                  </div>
                  <div class="field column is-5">
                    <label class="label">Prix enfant</label>
                    <div class="control">
                      <input
                        class="input"
                        type="number"
                        step="0.01"
                        v-model="prixEnfant"
                      />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Image</label>
                  <div class="file has-name is-boxed">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="resume"
                        @change="updateFiles"
                      />
                      <span class="file-cta">
                        <span class="file-label"> Choisissez une image </span>
                      </span>
                    </label>
                  </div>
                  <div
                    class="columns is-multiline"
                    v-for="file in files"
                    :key="file"
                  >
                    <div class="box column">
                      <p>
                        {{ file.name }}
                      </p>
                      <button class="delete" @click="deleteFile(file)"></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            v-if="elementName == null"
            @click="add()"
          >
            Ajouter un partenaire
          </button>
          <button
            class="button is-warning"
            v-if="elementName != null"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="closeUpdate">Cancel</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="closeDelete"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="elementActif.name != null">
            {{ elementActif.name }}
          </p>
          <p class="modal-card-title" v-if="elementActif.name == null">
            Ajouter un article
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeDelete"
          ></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer cet article ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="closeDelete">Cancel</button>
          <button
            class="button is-danger"
            v-if="elementActif.name != null"
            @click="deletePartenaire(elementActif.id)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      elements: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      elementName: null,
      elementActif: {},
      files: [],
      id: null,
      prixAdulte: null,
      prixEnfant: null,
      type: null,
      name: null,
      img: null,
    };
  },
  methods: {
    lauchCreate() {
      this.isActiveModalUpdate = true;
    },
    lauchDelete(id) {
      var proxy = { ...this.elements.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.elementActif = target_copy;
      this.isActiveModalDelete = true;
    },
    lauchUpdate(id) {
      var proxy = { ...this.elements.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      (this.id = id),
        (this.prixAdulte = target_copy.prixAdulte),
        (this.prixEnfant = target_copy.prixEnfant),
        (this.type = target_copy.type),
        (this.name = target_copy.name),
        (this.elementName = target_copy.name),
        (this.img = target_copy.img),
        (this.isActiveModalUpdate = true);
    },
    closeUpdate() {
      this.isActiveModalUpdate = false;
      (this.id = null),
        (this.prixAdulte = null),
        (this.prixEnfant = null),
        (this.type = null),
        (this.name = null),
        (this.elementName = null),
        (this.img = null);
    },
    closeDelete() {
      this.isActiveModalDelete = false;
      this.elementActif = {};
    },
    updateFiles(event) {
      const filesList = event.target.files;
      for (var i = 0; i < filesList.length; i++) {
        this.files.push(filesList[i]);
      }
    },
    deleteFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },
    add() {},
    update() {},
    deletePartenaire() {
      this.isActiveModalDelete = false;
      this.elementActif = {};
    },
  },
};
</script>
<style lang="scss"></style>
