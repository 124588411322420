<template>
  <div class="convocations">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="title">Convocations</h3>
          </div>
        </div>
      </section>
      <div class="return" @click="resetDatas" v-if="choosen_category != null">
        <img src="@/assets/icons/return.png" alt="return" />
      </div>
    </div>
    <div class="convocations-menu" v-if="choosen_category == null">
      <div class="columns is-multiline is-mobile">
        <div
          class="column is-half-mobile is-one-quarter-desktop"
          v-for="category in categories"
          :key="category.id"
        >
          <div
            class="category-item title is-4"
            @click="changeCategory(category.id)"
          >
            {{ category.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="convocations-part" v-if="choosen_category != null">
      <div class="columns is-multiline is-mobile">
        <div
          class="column is-one-third-desktop"
          v-for="convocation in convocations"
          :key="convocation.id"
        >
          <ConvocationComponent :convocation="convocation" />
        </div>
      </div>
    </div>
    <div class="code-input" v-if="choosen_category == 1 && code == null">
      <input
        v-for="(digit, index) in codes"
        :key="index"
        type="text"
        maxlength="1"
        v-model="codes[index]"
        @input="moveToNext($event, index)"
        ref="codeInputs"
      />
      <div class="error" v-if="error_message != null">
        {{ error_message }}
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ConvocationComponent from "@/components/convocations/ConvocationComponent.vue";
export default {
  data: function () {
    return {
      choosen_category: null,
      code: null,
      codes: ["", "", "", "", "", ""], // Tableau pour stocker chaque caractère
      categories: [],
      convocations: [],
      error_message: null,
    };
  },
  methods: {
    changeCategory(id) {
      if (id == 1 && this.code == null) {
        this.choosen_category = 1;
      } else {
        axios
          .get("public/convocations/" + id + "/" + this.code)
          .then((response) => {
            this.choosen_category = id;
            this.convocations = response.data;
            this.error_message = null;
          })
          .catch((error) => {
            console.log(error);
            this.code = null;
            this.codes = ["", "", "", "", "", ""];
            this.error_message = "Le code n'est pas bon veuillez réessayer";
          });
      }
    },
    resetDatas() {
      this.convocations = [];
      this.choosen_category = null;
      this.code = null;
      this.error_message = null;
      this.codes = ["", "", "", "", "", ""];
    },
    moveToNext(event, index) {
      if (event.target.value.length === 1 && index < this.codes.length - 1) {
        this.$refs.codeInputs[index + 1].focus(); // Passe au suivant
        this.error_message = null;
      }
      if (index == this.codes.length - 1) {
        this.code = this.codes.join("");
        this.changeCategory(this.choosen_category);
      }
    },
  },
  components: { ConvocationComponent },
  beforeMount() {
    axios
      .get("public/categories")
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.convocations {
  .hero {
    text-align: center;
    h3 {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/convocations.jpg");
      background-position: center;
    }
    .title {
      color: #ffffff;
    }
  }
  .return {
    height: 50px;
    width: 70px;
    background-color: #e75913;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    position: relative;
    margin: -70px auto 0 5%;
    padding: 20px;
    display: flex;
    align-items: center;

    img {
      vertical-align: middle;
    }
  }

  .convocations-menu {
    margin: 50px 20px 50px 20px;
    height: 100%;
    .category-item {
      padding: 1.5rem;
      text-align: center;
      background-color: #e75913;
      color: #000000;
      border-radius: 15px;
    }
    .category-item:hover {
      cursor: pointer;
    }
  }

  .convocations-part {
    margin: 50px 20px 20px 20px;
  }

  .code-input {
    text-align: center;
    margin-bottom: 30px;

    input {
      width: 40px;
      padding: 10px;
      margin: 5px;
      font-size: 18px;
      text-align: center;
      border-radius: 5px;
    }

    .error {
      color: red;
    }
  }
}
</style>
