<template>
  <div class="partenaire">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="title">Nos partenaires</h3>
          </div>
        </div>
      </section>
    </div>
    <div class="partenaire-body">
      <div class="columns is-multiline is-mobile">
        <div
          class="column is-one-fifth-desktop is-ont-third-mobile"
          v-for="partenaire in partenairesToDisplay"
          :key="partenaire.id"
        >
          <PartenaireItemComponents :partenaire="partenaire" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PartenaireItemComponents from "../components/partenaires/PartenaireItemComponents.vue";
import axios from "axios";

export default {
  data: function () {
    return {
      partenaires: [],
      partenairesToDisplay: [],
    };
  },
  components: { PartenaireItemComponents },
  beforeMount() {
    axios
      .get("public/partenaires")
      .then((response) => {
        this.partenaires = response.data;
        this.partenairesToDisplay = this.partenaires;
      })

      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.partenaire {
  .hero {
    text-align: center;
    h3 {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/partenaire.jpg");
      background-position: center;
    }
    .title {
      color: #000000;
    }
  }

  .partenaire-body {
    margin: 0 0 20px 20px;
    .columns {
      margin-right: 0;
      .column {
        height: inherit;
      }
    }
  }
}
</style>
