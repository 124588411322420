<template>
  <div class="admin" v-if="isAuthenticated()">
    <div class="component">
      <Results_Programme v-if="choosenComponent == 'articles'" />
      <Convocations v-if="choosenComponent == 'convocations'" />
      <EvenementComponent v-if="choosenComponent == 'events'" />
      <PlanningComponent v-if="choosenComponent == 'planning'" />
      <InformationsComponent v-if="choosenComponent == 'informations'" />
      <UtilisateursComponent v-if="choosenComponent == 'utilisateurs'" />
      <BoutiqueComponent v-if="choosenComponent == 'boutique'" />
      <PartenairesComponent v-if="choosenComponent == 'partenaires'" />
      <CategoriesComponent v-if="choosenComponent == 'categories'" />
      <MatchComponent v-if="choosenComponent == 'matchs'" />
      <TeamComponent v-if="choosenComponent == 'teams'" />
      <RoleComponent v-if="choosenComponent == 'roles'" />
      <TagComponent v-if="choosenComponent == 'tags'" />
    </div>
  </div>
</template>
<script>
import axios from "axios";

import Results_Programme from "@/components/administration/ArticlesComponent.vue";
import Convocations from "@/components/administration/ConvocationsComponent.vue";
import PlanningComponent from "@/components/administration/PlanningComponent.vue";
import EvenementComponent from "../components/administration/EvenementComponent.vue";
import InformationsComponent from "../components/administration/InformationsComponent.vue";
import PartenairesComponent from "@/components/administration/PartenairesComponent.vue";
import BoutiqueComponent from "@/components/administration/BoutiqueComponent.vue";
import UtilisateursComponent from "@/components/administration/UtilisateursComponent.vue";
// import JoueursComponent from "@/components/administration/JoueursComponent.vue";
import CategoriesComponent from "@/components/administration/CategoriesComponent.vue";
import MatchComponent from "@/components/administration/MatchComponent.vue";
import TeamComponent from "@/components/administration/TeamComponent.vue";
import RoleComponent from "@/components/administration/RoleComponent.vue";
import TagComponent from "@/components/administration/TagComponent.vue";
export default {
  props: ["choosenComponent"],
  data() {
    return {
      //component: "results",
      //active: "results",
    };
  },
  methods: {
    isAuthenticated() {
      if (this.$store.state.token != "null") {
        return true;
      } else {
        this.$router.push({ name: "login" });
        return false;
      }
    },
  },
  components: {
    Results_Programme,
    Convocations,
    PlanningComponent,
    EvenementComponent,
    InformationsComponent,
    PartenairesComponent,
    BoutiqueComponent,
    UtilisateursComponent,
    // JoueursComponent,
    CategoriesComponent,
    MatchComponent,
    TeamComponent,
    RoleComponent,
    TagComponent,
  },
  beforeCreate() {
    const token = this.$store.state.token;

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .post("public/check")
      .then((response) => {
        if (response.data == "") {
          localStorage.setItem("token", "null");
          localStorage.setItem("abilities", "null");
          localStorage.setItem("isAuthenticated", false);
          this.$router.push({ name: "home" });
        }
      });

    if (this.$store.state.token != "null") {
      return true;
    } else {
      this.$router.push({ name: "login" });
    }
  },
};
</script>

<style lang="scss">
.admin {
  margin-top: 60px;
  background-color: #ffffff;

  .menu {
    margin-left: 20px;
    .isMenuActive {
      background-color: #e75913;
      color: #ffffff;
    }
  }

  .component {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    width: 95%;
  }
}
</style>
