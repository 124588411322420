import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import SplitCarousel from "vue-split-carousel";
import Switches from "vue-switches";
import axios from "axios";
import VueSocialSharing from "vue-social-sharing";

axios.defaults.baseURL = "https://api.saintcyrherbignacfootball.fr/api/";

createApp(App)
  .use(store)
  .use(router, axios)
  .use(SplitCarousel)
  .use(Switches)
  .use(VueSocialSharing)
  .mount("#app");
