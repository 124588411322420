<template>
  <div>
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter une catégorie
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="category in categories" :key="category.id">
          <td>{{ category.name }}</td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(category.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone de crayon"
                />
              </div>
            </button>

            <button class="button is-danger" @click="lauchDelete(category.id)">
              <div class="icon">
                <img
                  src="@/assets/icons/trash-can-solid.svg"
                  alt="icon de poubelle"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="categoryName != null">
            {{ categoryName }}
          </p>
          <p class="modal-card-title" v-if="categoryName == null">
            Ajouter une catégorie
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="field">
                  <label class="label">Nom</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Nom"
                      v-model="name"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="checkbox">
                    <input type="checkbox" v-model="has_drivers" />
                    Possède des conducteurs ?
                  </label>
                </div>
                <div class="field">
                  <label class="checkbox">
                    <input type="checkbox" v-model="has_shirts" />
                    Les joueurs nettoient les maillots ?
                  </label>
                </div>
                <div class="field">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox" v-model="has_cleaners" />
                      Les joueurs nettoient les vestaires ?
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            v-if="is_create == true"
            @click="add()"
          >
            Ajouter cette catégorie
          </button>
          <button
            class="button is-warning"
            v-if="is_create == false"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="close">Annuler</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="categoryActif.name != null">
            {{ categoryActif.name }}
          </p>
          <p class="modal-card-title" v-if="categoryActif.name == null">
            Ajouter une catégorie
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer cette catégorie ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="close">Annuler</button>
          <button
            class="button is-danger"
            v-if="categoryActif.name != null"
            @click="deleteCategory(categoryActif)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: function () {
    return {
      categories: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      categoryActif: [],
      categoryName: null,
      is_create: false,
      id: null,
      name: null,
      has_drivers: false,
      has_shirts: false,
      has_cleaners: false,
      error_messsage: null,
      is_failure: false,
    };
  },
  methods: {
    initializeData() {
      (this.categoryName = null),
        (this.is_create = false),
        (this.id = null),
        (this.name = null),
        (this.has_drivers = false),
        (this.has_shirts = false),
        (this.has_cleaners = false),
        (this.categoryActif = []),
        (this.isActiveModalUpdate = false),
        (this.error_messsage = null),
        (this.is_failure = false),
        (this.isActiveModalDelete = false);
    },
    close() {
      this.initializeData();
    },
    checkRequired() {
      if (this.name == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseignez un nom de catégorie.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      return true;
    },

    //Partie ajout
    lauchCreate() {
      this.isActiveModalUpdate = true;
      this.is_create = true;
    },
    add() {
      const formData = {
        name: this.name,
        has_drivers: this.has_drivers,
        has_shirts: this.has_shirts,
        has_cleaners: this.has_cleaners,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .post("categories", formData)
        .then((response) => {
          this.categories.push(response.data);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //partie modification
    lauchUpdate(id) {
      this.is_create = false;
      this.isActiveModalUpdate = true;

      var proxy = { ...this.categories.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      (this.categoryName = target_copy.name),
        (this.id = target_copy.id),
        (this.name = target_copy.name),
        (this.has_drivers = target_copy.has_drivers),
        (this.has_shirts = target_copy.has_shirts),
        (this.has_cleaners = target_copy.has_cleaners);
    },
    update(id) {
      const formData = {
        name: this.name,
        has_drivers: this.has_drivers,
        has_shirts: this.has_shirts,
        has_cleaners: this.has_cleaners,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .put("categories/" + id, formData)
        .then((response) => {
          const index = this.categories.findIndex((obj) => obj.id == id);
          this.categories[index] = response.data;
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //partie suppression
    lauchDelete(id) {
      this.is_create = false;

      var proxy = { ...this.categories.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.categoryActif = target_copy;
      this.isActiveModalDelete = true;
    },
    deleteCategory(category) {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .delete("categories/" + category.id)
        .then(() => {
          const index = this.categories.indexOf(category);
          this.categories.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("categories")
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss"></style>
