<template>
  <section class="hero is-primary is-fullheight login">
    <div class="hero-body">
      <div class="login-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-10-tablet is-10-desktop is-10-widescreen">
              <div class="box">
                <div class="field">
                  <label for="" class="label">Email</label>
                  <div class="control has-icons-left">
                    <input
                      type="text"
                      name="email"
                      v-model="email"
                      placeholder="email@hotmail.com"
                      class="input"
                    />
                  </div>
                </div>

                <div class="field">
                  <label for="" class="label">Code secret</label>
                  <div class="control has-icons-left">
                    <input
                      type="text"
                      name="code"
                      v-model="code"
                      placeholder="Votre code"
                      class="input"
                    />
                  </div>
                </div>

                <div class="field">
                  <label for="" class="label">Mot de passe</label>
                  <div class="control has-icons-left">
                    <input
                      v-model="password"
                      type="password"
                      placeholder="*******"
                      class="input"
                      name="password"
                    />
                  </div>
                </div>
                <div class="field">
                  <label for="" class="label"
                    >Confirmer votre mot de passe</label
                  >
                  <div class="control has-icons-left">
                    <input
                      v-model="password_confirm"
                      type="password"
                      placeholder="*******"
                      class="input"
                      name="password"
                    />
                  </div>
                </div>
                <div class="field">
                  <button
                    type="submit"
                    class="button is-login"
                    @click="submitForm()"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="notification is-success is-light" v-if="is_success">
          {{ success_message }}
        </div>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: null,
      password: null,
      password_confirm: null,
      code: null,
      is_success: false,
      is_failure: false,
      error_messsage: null,
      success_message: null,
    };
  },
  methods: {
    checkRequired() {
      if (this.email == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez remplir le champ email";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.code == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner votre code reçu par mail";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.password == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner votre mot de passe";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.password != this.password_confirm) {
        this.is_failure = true;
        this.error_messsage = "Les deux mots de passes ne sont pas identiques";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      return true;
    },
    datenow() {
      let yourDate = new Date();
      yourDate.toISOString().split("T")[0];
      const offset = yourDate.getTimezoneOffset();
      yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);

      var dateFormat = yourDate.toISOString().split("T");
      var firstPart = dateFormat[0];
      var secondPart = dateFormat[1].split(".")[0];

      yourDate = firstPart + " " + secondPart;

      return yourDate;
    },
    submitForm() {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        email: this.email,
        code: this.code,
        password: this.password,
        date: this.datenow(),
      };

      axios
        .post("public/update/password", formData)
        .then((response) => {
          this.is_success = true;
          this.success_message = response.data;
          setTimeout(() => {
            this.is_success = false;
            this.$router.push({ name: "home" });
          }, 3000);
        })
        .catch((error) => {
          this.is_failure = true;
          this.error_messsage = error.response.data.message;
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
        });
    },
  },
};
</script>
<style lang="scss">
.login {
  .login-body {
    display: block;
    width: 100%;

    .notification {
      margin-top: 15px;
    }

    .field {
      .is-login {
        background-color: #e75913;
        color: #ffffff;
      }
    }
  }
}
</style>
