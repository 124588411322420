<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Mentions Légales</h1>
      <div class="content">
        <h2>Éditeur du site</h2>
        <p>
          Le site <strong>Saint Cyr Herbignac Football</strong> est édité par :
        </p>
        <ul>
          <li><strong>Nom du club :</strong> Saint Cyr Herbignac Football</li>
          <li>
            <strong>Siège social :</strong> Complexe Sportif Louis
            Bretecher44410 Herbignac, France
          </li>
          <!-- <li><strong>Email :</strong> [Adresse email de contact du club]</li> -->
        </ul>

        <!-- <h2>Directeur de publication</h2>
        <p>
          Le directeur de la publication est
          <strong>[Nom du directeur, par exemple : Jean Dupont]</strong>, en
          qualité de <strong>Président du club</strong>.
        </p> -->

        <h2>Hébergement du site</h2>
        <p>Le site est hébergé par :</p>
        <ul>
          <li><strong>Nom de l'hébergeur :</strong> IONOS SARL</li>
          <li>
            <strong>Adresse :</strong> 7 Place de la Gare, 57200 Sarreguemines,
            France
          </li>
          <li><strong>Téléphone :</strong> 0970 808 911</li>
          <li>
            <strong>Site web :</strong>
            <a href="https://www.ionos.fr" target="_blank">www.ionos.fr</a>
          </li>
        </ul>

        <h2>Propriété intellectuelle</h2>
        <p>
          L'ensemble des contenus présents sur le site (textes, images, logos,
          vidéos, etc.) sont protégés par des droits d'auteur. Toute
          reproduction, distribution, modification ou exploitation, sous quelque
          forme que ce soit, sans autorisation expresse du club Saint Cyr
          Herbignac Football, est interdite.
        </p>

        <h2>Données personnelles</h2>
        <p>
          Le traitement des données personnelles est effectué dans le respect de
          la réglementation en vigueur, notamment du Règlement Général sur la
          Protection des Données (RGPD). Pour plus d'informations, veuillez
          consulter notre
          <a href="[Lien vers la politique de confidentialité]"
            >Politique de Confidentialité</a
          >.
        </p>
        <p>
          Vous disposez d'un droit d'accès, de rectification, de suppression ou
          de limitation des données vous concernant. Pour exercer ces droits,
          vous pouvez nous contacter à l'adresse suivante :
          <strong>herbignacfoot.site@gmail.com</strong>.
        </p>

        <h2>Liens externes</h2>
        <p>
          Le site peut contenir des liens vers d'autres sites web.
          <strong>Saint Cyr Herbignac Football</strong> ne peut être tenu
          responsable du contenu de ces sites tiers ni des éventuels dommages
          pouvant résulter de la navigation sur ces sites externes.
        </p>

        <h2>Responsabilité</h2>
        <p>
          <strong>Saint Cyr Herbignac Football</strong> ne saurait être tenu
          responsable des dommages directs ou indirects, pertes ou frais
          résultant de l'utilisation de ce site, ou de l'incapacité de
          l'utiliser, ou d'un mauvais fonctionnement, interruption, virus
          informatique, ou problème lié à la connexion Internet.
        </p>

        <h2>Loi applicable</h2>
        <p>
          Les présentes mentions légales sont régies par le droit français. En
          cas de litige, et après une tentative de recherche de solution à
          l'amiable, compétence est attribuée aux tribunaux compétents.
        </p>

        <p class="has-text-right">
          <strong>Date de la dernière mise à jour : 01/01/2024</strong>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MentionsLegales",
};
</script>

<style>
.container {
  margin-top: 20px;
}
</style>
