<template>
  <div class="home-game card">
    <header class="card-header">
      <div class="card-header-title">
        {{ game.type.name }} {{ game.type.icon }}
      </div>
      <div class="card-header-icon">
        <span class="icon" v-if="game.is_home">
          <img src="@/assets/icons/house.svg" alt="icone maison" />
        </span>
        <span class="icon" v-if="!game.is_home">
          <img src="@/assets/icons/plane-solid.svg" alt="icone avion" />
        </span>
      </div>
    </header>
    <div class="card-content">
      <div class="media">
        <div
          class="media-content columns is-multiline is-mobile"
          v-if="game.is_home"
        >
          <div class="column is-full score-parent">
            <div class="columns score-part">
              <div class="column is-one-third sch-part">
                <div class="sch-team content is-large">
                  {{ sch_libelle }}
                </div>
                <div class="content is-small">
                  {{ game.team.name }}
                </div>
              </div>
              <div class="score column" v-if="game.is_finish">
                {{ displayGoals(game.sch_goals) }} :
                {{ displayGoals(game.opponent_goals) }}
              </div>
              <div class="score column" v-if="!game.is_finish">_ : _</div>
              <div class="column is-one-third adversaire-team">
                {{ game.opponent }}
              </div>
            </div>
          </div>
          <div class="details-parent column is-full">
            <p class="subtitle is-6" datetime="2016-1-1">
              {{ dateFormat(game.date) }} {{ game.hour }}
            </p>
          </div>
          <div class="description-parent column is-full">
            <p class="subtitle is-size-6-desktop is-size-7-mobile comment">
              {{ game.comment }}
            </p>
          </div>
        </div>
        <div
          class="media-content columns is-multiline is-mobile"
          v-if="!game.is_home"
        >
          <div class="column is-full score-parent">
            <div class="columns score-part">
              <div class="column is-one-third adversaire-team">
                {{ game.opponent }}
              </div>
              <div class="score column" v-if="game.is_finish">
                {{ displayGoals(game.opponent_goals) }} :
                {{ displayGoals(game.sch_goals) }}
              </div>
              <div class="score column" v-if="!game.is_finish">_ : _</div>
              <div class="column is-one-third sch-part">
                <div class="content is-large sch-team">
                  {{ sch_libelle }}
                </div>
                <div class="content is-small">
                  {{ game.team.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="details-parent column is-full">
            <p class="subtitle is-6" datetime="2016-1-1">
              {{ dateFormat(game.date) }} {{ game.hour }}
            </p>
          </div>
          <div class="description-parent column is-full">
            <p class="subtitle is-size-6-desktop is-size-7-mobile comment">
              {{ game.comment }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["game"],
  data: function () {
    return {
      sch_libelle: "SCH",
    };
  },
  methods: {
    displayGoals(goals) {
      if (goals == 0) {
        return "0";
      }
      return goals;
    },
    dateFormat(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var newDate = new Date(date);
      newDate = newDate.toLocaleDateString("fr-FR", options);
      return newDate;
    },
  },
};
</script>

<style lang="scss">
.home-game {
  .card-content {
    padding: 0.5rem 0;
    .media {
      .media-content {
        .score-parent {
          .score-part {
            padding-left: 30px;
            padding-right: 30px;
            font-weight: 500;
            .sch-part {
              .sch-team {
                font-weight: bold;
                color: #ec8a5a;
                margin-bottom: 0;
              }
            }
          }
        }
        .details-parent,
        .description-parent {
          padding: 0.25rem 0;
        }
        .comment {
          padding: 10px;
        }
      }
    }
  }
}
</style>
