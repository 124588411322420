<template>
  <div class="users-admin">
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter un utilisateur
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Nom Prénom</th>
          <th>Catégorie</th>
          <th>Photo</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="player in users" :key="player.id">
          <td>{{ player.name }} {{ player.firstname }}</td>
          <td v-if="player.player_category">
            Joueur : {{ player.player_category.name }}
          </td>
          <td v-if="!player.player_category">Pas un joueur</td>
          <td v-if="player.picture != null">
            <figure class="image">
              <img :src="baseURL + player.picture" alt="Photo de la personne" />
            </figure>
          </td>
          <td v-if="player.picture == null">❌</td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(player.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone du crayon"
                />
              </div>
            </button>

            <button class="button is-danger" @click="lauchDelete(player.id)">
              <div class="icon">
                <img
                  src="@/assets/icons/trash-can-solid.svg"
                  alt="icone de poubelle"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="!is_create">
            {{ playerName }} {{ playerFirstName }}
          </p>
          <p class="modal-card-title" v-if="is_create">
            Ajouter un utilisateur
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-12-mobile is-12-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="columns">
                  <div class="field column is-5">
                    <label class="label">Nom</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Nom"
                        v-model="name"
                      />
                    </div>
                  </div>
                  <div class="field column is-5">
                    <label class="label">Prenom</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Prenom"
                        v-model="firstname"
                      />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Email</label>
                  <div class="control">
                    <input
                      class="input"
                      type="email"
                      name="email"
                      id="email"
                      v-model="email"
                      placeholder="Email"
                    />
                  </div>
                  <p class="help is-info">
                    L'utilisateur ne pourra pas se connecter si il n'a pas
                    d'email
                  </p>
                </div>

                <div class="columns">
                  <div class="field column is-5">
                    <label class="label">Téléphone</label>
                    <div class="control">
                      <input
                        class="input"
                        type="phone"
                        name="phone"
                        v-model="phone"
                        id="phone"
                        placeholder="Telephone"
                      />
                    </div>
                  </div>
                  <div class="field column is-5">
                    <label class="label">Birthday</label>
                    <div class="control">
                      <input class="input" type="date" v-model="birthday" />
                    </div>
                  </div>
                </div>

                <div class="field no-game-part">
                  <label class="label">La personne peut se connecter ?</label>
                  <div class="control">
                    <input type="checkbox" v-model="is_actif" />
                  </div>
                </div>

                <div
                  class="field no-game-part"
                  v-if="checkAbilities('store_roles')"
                >
                  <label class="label"
                    >La personne est un administrateur ?</label
                  >
                  <div class="control">
                    <input type="checkbox" v-model="is_admin" />
                  </div>
                </div>

                <div class="columns">
                  <div class="field column is-5">
                    <label class="label">Bureau</label>
                    <div class="control">
                      <input type="checkbox" v-model="is_bureau" />
                    </div>
                  </div>
                  <div class="field column is-5" v-if="is_bureau">
                    <label class="label">Fonction</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Role"
                        v-model="bureau_fonction"
                      />
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div class="field column is-5">
                    <label class="label">Coach</label>
                    <div class="control">
                      <input type="checkbox" v-model="is_coach" />
                    </div>
                  </div>
                  <div class="field column is-5" v-if="is_coach">
                    <label class="label">Categorie</label>
                    <div class="control">
                      <div class="select">
                        <select v-model="coach_category">
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div class="field column is-5">
                    <label class="label">Player</label>
                    <div class="control">
                      <input type="checkbox" v-model="is_player" />
                    </div>
                  </div>
                  <div class="field column is-5" v-if="is_player">
                    <label class="label">Categorie</label>
                    <div class="control">
                      <div class="select">
                        <select v-model="player_category">
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Bénévole</label>
                  <div class="control">
                    <input type="checkbox" v-model="is_benevole" />
                  </div>
                </div>

                <div class="field" v-if="!is_create">
                  <label class="label">Ancienne photo</label>
                  <td v-if="picture != null">
                    <figure class="image">
                      <img :src="baseURL + picture" alt="ancienne photo" />
                    </figure>
                  </td>
                  <td v-if="picture == null">❌</td>
                </div>

                <div class="field" v-if="tempUrl == null">
                  <label class="label">Photo</label>
                  <div class="file has-name is-boxed">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="resume"
                        @change="updateFile"
                      />
                      <span class="file-cta">
                        <span class="file-label"> Choisissez une photo </span>
                      </span>
                    </label>
                  </div>
                </div>

                <div class="field" v-if="tempUrl">
                  <div class="column">
                    <label class="label">Nouvelle photo</label>
                    <figure class="image">
                      <img :src="tempUrl" alt="nouvelle photo" />
                    </figure>
                    <button class="delete" @click="deleteFile"></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <button class="button is-success" v-if="is_create" @click="add()">
            Ajouter un utilisateur
          </button>
          <button
            class="button is-warning"
            v-if="!is_create"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="close">Annuler</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="userActif.name != null">
            {{ userActif.name }} {{ userActif.firstname }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer cet utilisateur ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="close">Annuler</button>
          <button
            class="button is-danger"
            v-if="userActif.name != null"
            @click="deleteUsers(userActif)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: function () {
    return {
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
      users: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      userActif: {},
      file: null,
      playerName: null,
      playerFirstName: null,
      id: null,
      name: null,
      firstname: null,
      email: null,
      phone: null,
      is_bureau: false,
      is_coach: false,
      is_benevole: false,
      is_player: false,
      is_actif: false,
      is_admin: false,
      bureau_fonction: null,
      picture_name: null,
      birthday: null,
      coach_category: null,
      player_category: null,
      is_create: null,
      tempUrl: null,
      role: null,
      is_failure: false,
      error_messsage: null,
    };
  },
  methods: {
    initializeData() {
      (this.playerName = null),
        (this.playerFirstName = null),
        (this.id = null),
        (this.name = null),
        (this.firstname = null),
        (this.phone = null),
        (this.email = null),
        (this.is_bureau = false),
        (this.is_coach = false),
        (this.is_benevole = false),
        (this.is_actif = false),
        (this.is_player = false),
        (this.bureau_fonction = null),
        (this.picture_name = null),
        (this.birthday = null),
        (this.coach_category = null),
        (this.player_category = null),
        (this.isActiveModalUpdate = false),
        (this.isActiveModalDelete = false),
        (this.file = null),
        (this.tempUrl = null),
        (this.role = null),
        (this.is_admin = false),
        (this.is_create = null);
    },
    close() {
      this.initializeData();
    },
    checkRequired() {
      if (this.name == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner un nom";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.firstname == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner un prénom";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.is_coach && this.coach_category == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez selectionner une catégorie de coach";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.is_bureau && this.bureau_fonction == null) {
        this.is_failure = true;
        this.error_messsage =
          "Veuillez selectionner une fonction dans le bureau";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.is_player && this.player_category == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez selectionner une catégorie de joueur";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      return true;
    },

    //partie ajout

    lauchCreate() {
      this.isActiveModalUpdate = true;
      this.is_create = true;
    },
    add() {
      if (!this.checkRequired()) {
        return;
      }

      const formDataPicture = new FormData();
      formDataPicture.append("picture", this.file);
      if (this.file !== null) {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .post("users/upload", formDataPicture)
          .then((response) => {
            this.picture_name = response.data;
            const formData = {
              name: this.name,
              firstname: this.firstname,
              phone: this.phone,
              email: this.email,
              is_bureau: this.is_bureau,
              is_coach: this.is_coach,
              is_benevole: this.is_benevole,
              is_player: this.is_player,
              is_actif: this.is_actif,
              bureau_fonction: this.bureau_fonction,
              picture_name: this.picture_name,
              birthday: this.birthday,
              coach_category: this.coach_category,
              player_category: this.player_category,
              role: this.assignRole(),
            };

            axios
              .create({
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .post("users", formData)
              .then((response) => {
                if (this.is_admin) {
                  axios
                    .create({
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                    .get("users/update/a/" + response.data.id, formData)
                    .then(() => {
                      this.users.push(response.data);
                      this.initializeData();
                    });
                } else {
                  this.users.push(response.data);
                  this.initializeData();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formData = {
          name: this.name,
          firstname: this.firstname,
          phone: this.phone,
          email: this.email,
          is_bureau: this.is_bureau,
          is_coach: this.is_coach,
          is_benevole: this.is_benevole,
          is_player: this.is_player,
          is_actif: this.is_actif,
          bureau_fonction: this.bureau_fonction,
          picture_name: this.picture_name,
          birthday: this.birthday,
          coach_category: this.coach_category,
          player_category: this.player_category,
          role: this.assignRole(),
        };

        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .post("users", formData)
          .then((response) => {
            if (this.is_admin) {
              axios
                .create({
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .get("users/update/a/" + response.data.id, formData)
                .then(() => {
                  this.users.push(response.data);
                  this.initializeData();
                });
            } else {
              this.users.push(response.data);
              this.initializeData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    //partie suppression
    lauchDelete(id) {
      this.is_create = false;

      var proxy = { ...this.users.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.userActif = target_copy;
      this.isActiveModalDelete = true;
    },
    deleteUsers(user) {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .delete("users/" + user.id)
        .then(() => {
          const index = this.users.indexOf(user);
          this.users.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //partie update

    lauchUpdate(id) {
      var proxy = { ...this.users.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);

      (this.playerName = target_copy.name),
        (this.playerFirstName = target_copy.firstname),
        (this.id = target_copy.id),
        (this.name = target_copy.name),
        (this.firstname = target_copy.firstname),
        (this.phone = target_copy.phone),
        (this.email = target_copy.email),
        (this.is_bureau = target_copy.is_bureau == 1 ? true : false),
        (this.is_coach = target_copy.is_coach == 1 ? true : false),
        (this.is_benevole = target_copy.is_benevole == 1 ? true : false),
        (this.is_player = target_copy.is_player == 1 ? true : false),
        (this.is_actif = target_copy.is_actif == 1 ? true : false),
        (this.bureau_fonction = target_copy.bureau_fonction),
        (this.picture_name = target_copy.picture),
        (this.picture = target_copy.picture),
        (this.birthday = target_copy.birthday),
        (this.is_admin = target_copy.is_admin),
        (this.coach_category =
          target_copy.coach_category != null
            ? target_copy.coach_category.id
            : null),
        (this.player_category =
          target_copy.player_category != null
            ? target_copy.player_category.id
            : null),
        (this.is_create = this.false),
        (this.file = null),
        (this.isActiveModalUpdate = true);
    },
    update(id) {
      if (!this.checkRequired()) {
        return;
      }

      const formDataPicture = new FormData();
      formDataPicture.append("picture", this.file);
      if (this.file !== null) {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .post("users/upload", formDataPicture)
          .then((response) => {
            this.picture_name = response.data;
            const formData = {
              name: this.name,
              firstname: this.firstname,
              phone: this.phone,
              email: this.email,
              is_bureau: this.is_bureau,
              is_coach: this.is_coach,
              is_benevole: this.is_benevole,
              is_player: this.is_player,
              is_actif: this.is_actif,
              bureau_fonction: this.bureau_fonction,
              picture_name: this.picture_name,
              birthday: this.birthday,
              coach_category: this.coach_category,
              player_category: this.player_category,
              role: this.assignRole(),
            };

            axios
              .create({
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .put("users/" + id, formData)
              .then((response) => {
                if (this.is_admin) {
                  axios
                    .create({
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                    .get("users/update/a/" + response.data.id, formData)
                    .then(() => {
                      const index = this.users.findIndex((obj) => obj.id == id);
                      this.users[index] = response.data;
                      this.initializeData();
                    });
                } else {
                  const index = this.users.findIndex((obj) => obj.id == id);
                  this.users[index] = response.data;
                  this.initializeData();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formData = {
          name: this.name,
          firstname: this.firstname,
          phone: this.phone,
          email: this.email,
          is_bureau: this.is_bureau,
          is_coach: this.is_coach,
          is_benevole: this.is_benevole,
          is_player: this.is_player,
          is_actif: this.is_actif,
          bureau_fonction: this.bureau_fonction,
          picture_name: this.picture_name,
          birthday: this.birthday,
          coach_category: this.coach_category,
          player_category: this.player_category,
          role: this.assignRole(),
        };

        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .put("users/" + id, formData)
          .then((response) => {
            if (this.is_admin) {
              axios
                .create({
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .get("users/update/a/" + response.data.id, formData)
                .then(() => {
                  const index = this.users.findIndex((obj) => obj.id == id);
                  this.users[index] = response.data;
                  this.initializeData();
                });
            } else {
              const index = this.users.findIndex((obj) => obj.id == id);
              this.users[index] = response.data;
              this.initializeData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    updateFile(e) {
      if (e.target.files[0].size > 5000000) {
        this.is_failure = true;
        this.error_messsage = "Image trop volumineuse";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return;
      }
      this.file = e.target.files[0];
      this.tempUrl = URL.createObjectURL(this.file);
    },
    deleteFile() {
      this.file = null;
      this.tempUrl = null;
    },
    assignRole() {
      var role = "user";
      if (this.is_bureau && this.is_coach && this.is_benevole)
        role = "bureau_coach_benevole";
      else if (this.is_bureau && this.is_coach) role = "coach_bureau";
      else if (this.is_benevole && this.is_coach) role = "coach_benevole";
      else if (this.is_bureau && this.is_benevole) role = "bureau_benevole";
      else if (this.is_coach) role = "coach";
      else if (this.is_bureau) role = "bureau";
      else if (this.is_benevole) role = "benevole";
      return role;
    },
    checkAbilities(ability) {
      if (localStorage.getItem("abilities") != null) {
        if (localStorage.getItem("abilities").includes(ability)) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("users")
      .then((response) => {
        this.users = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("categories")
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.users-admin {
  .table {
    .image {
      img {
        max-height: 50px;
        width: auto;
      }
    }
  }

  .field {
    .image {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
}
</style>
