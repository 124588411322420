<template>
  <div class="office">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="title">Organigramme</h3>
          </div>
        </div>
      </section>
    </div>
    <div class="component">
      <OfficeComponent
        :benevoles="users"
        :dirigeants="dirigeants"
        :members="members"
      />
    </div>
  </div>
</template>

<script>
import OfficeComponent from "@/components/communs/OfficeComponent.vue";
import axios from "axios";

export default {
  name: "OfficeView",
  components: { OfficeComponent },
  data: function () {
    return {
      benevoles: [],
      dirigeants: [],
      members: [],
      users: [],
    };
  },
  beforeMount() {
    axios
      .get("public/organigramme")
      .then((response) => {
        this.benevoles = response.data.benevoles;
        this.dirigeants = response.data.coach;
        this.members = response.data.bureau;
      })

      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
//hero part

.office {
  .hero {
    text-align: center;
    h3 {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/office.jpg");
    }
    .title {
      color: #ffffff;
    }
  }
}
</style>
