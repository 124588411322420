<template>
  <div class="partenaire-admin">
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter un partenaire
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Image</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="partenaire in partenaires" :key="partenaire.id">
          <td>{{ partenaire.name }}</td>
          <td v-if="partenaire.picture != null">
            <figure class="image">
              <img
                :src="baseURL + partenaire.picture"
                alt="Photo du partenaire"
              />
            </figure>
          </td>
          <td v-if="partenaire.picture == null">❌</td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(partenaire.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone de crayon"
                />
              </div>
            </button>

            <button
              class="button is-danger"
              @click="lauchDelete(partenaire.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/trash-can-solid.svg"
                  alt="icone de poubelle"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="!is_create">
            {{ partenaireName }}
          </p>
          <p class="modal-card-title" v-if="is_create">Ajouter un partenaire</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-12-mobile is-12-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="field">
                  <label class="label">Nom</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Nom"
                      v-model="name"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Email</label>
                  <div class="control">
                    <input
                      class="input"
                      type="email"
                      name="email"
                      id="email"
                      v-model="email"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Téléphone</label>
                  <div class="control">
                    <input
                      class="input"
                      type="phone"
                      name="phone"
                      v-model="phone"
                      id="phone"
                      placeholder="Telephone"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Adresse</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Adresse"
                      v-model="address"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="field column is-4">
                    <label class="label">Code Postale</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Code postale"
                        v-model="zip"
                      />
                    </div>
                  </div>
                  <div class="field column is-7">
                    <label class="label">Ville</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Ville"
                        v-model="city"
                      />
                    </div>
                  </div>
                </div>

                <div class="field" v-if="!is_create">
                  <label class="label">Ancien logo</label>
                  <td v-if="picture != null">
                    <figure class="image">
                      <img
                        :src="baseURL + picture"
                        alt="Ancien logo du partenaire"
                      />
                    </figure>
                  </td>
                  <td v-if="picture == null">❌</td>
                </div>

                <div class="field" v-if="tempUrl == null">
                  <label class="label">Logo</label>
                  <div class="file has-name is-boxed">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="resume"
                        @change="updateFile"
                      />
                      <span class="file-cta">
                        <span class="file-label"> Choisissez un logo </span>
                      </span>
                    </label>
                  </div>
                </div>

                <div class="field" v-if="tempUrl">
                  <div class="column">
                    <label class="label">Nouveau logo</label>
                    <figure class="image">
                      <img :src="tempUrl" alt="nouveau logo" />
                    </figure>
                    <button class="delete" @click="deleteFile"></button>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Site web</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Site Web"
                      v-model="website"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <button class="button is-success" v-if="is_create" @click="add()">
            Ajouter un partenaire
          </button>
          <button
            class="button is-warning"
            v-if="!is_create"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="partenaireActif.name != null">
            {{ partenaireActif.name }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer ce partenaire ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-danger"
            v-if="partenaireActif.name != null"
            @click="deletePartenaire(partenaireActif)"
          >
            Confirmer suppression
          </button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: function () {
    return {
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
      is_create: false,
      partenaires: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      partenaireActif: {},
      file: null,
      id: null,
      partenaireName: null,
      name: null,
      address: null,
      city: null,
      zip: null,
      phone: null,
      email: null,
      picture: null,
      website: null,
      tempUrl: null,
      picture_name: null,
      error_messsage: null,
      is_failure: false,
    };
  },
  methods: {
    initializeData() {
      (this.partenaireName = null),
        (this.is_create = false),
        (this.id = null),
        (this.name = null),
        (this.address = null),
        (this.city = null),
        (this.zip = null),
        (this.phone = null),
        (this.email = null),
        (this.picture = null),
        (this.website = null),
        (this.partenaireActif = []),
        (this.isActiveModalUpdate = false),
        (this.file = null),
        (this.tempUrl = null),
        (this.picture_name = null),
        (this.error_messsage = null),
        (this.is_failure = false),
        (this.isActiveModalDelete = false);
    },
    close() {
      this.initializeData();
    },
    checkRequired() {
      if (this.name == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner un nom.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }
      return true;
    },

    //Partie ajout
    lauchCreate() {
      this.isActiveModalUpdate = true;
      this.is_create = true;
    },
    add() {
      if (!this.checkRequired) {
        return;
      }
      const formDataPicture = new FormData();
      formDataPicture.append("picture", this.file);
      if (this.file !== null) {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .post("partenaires/upload", formDataPicture)
          .then((response) => {
            this.picture_name = response.data;
            const formData = {
              email: this.email,
              phone: this.phone,
              address: this.address,
              website: this.website,
              picture: this.picture_name,
              name: this.name,
              city: this.city,
              zip: this.zip,
            };

            axios
              .create({
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .post("partenaires", formData)
              .then((response) => {
                this.partenaires.push(response.data);
                this.initializeData();
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formData = {
          email: this.email,
          phone: this.phone,
          address: this.address,
          website: this.website,
          picture: this.picture,
          name: this.name,
          city: this.city,
          zip: this.zip,
        };

        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .post("partenaires", formData)
          .then((response) => {
            this.partenaires.push(response.data);
            this.initializeData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    //Partie modification
    lauchUpdate(id) {
      var proxy = { ...this.partenaires.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      (this.id = id),
        (this.name = target_copy.name),
        (this.partenaireName = target_copy.name),
        (this.address = target_copy.address),
        (this.city = target_copy.city),
        (this.zip = target_copy.zip),
        (this.phone = target_copy.phone),
        (this.email = target_copy.email),
        (this.picture = target_copy.picture),
        (this.website = target_copy.website),
        (this.isActiveModalUpdate = true);
    },

    //partie suppression
    lauchDelete(id) {
      this.is_create = false;

      var proxy = { ...this.partenaires.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.partenaireActif = target_copy;
      this.isActiveModalDelete = true;
    },
    deletePartenaire(partenaire) {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .delete("partenaires/" + partenaire.id)
        .then(() => {
          const index = this.partenaires.indexOf(partenaire);
          this.partenaires.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateFile(e) {
      this.file = e.target.files[0];
      this.tempUrl = URL.createObjectURL(this.file);
    },
    deleteFile() {
      this.file = null;
      this.tempUrl = null;
    },

    update(id) {
      if (!this.checkRequired) {
        return;
      }
      const formDataPicture = new FormData();
      formDataPicture.append("picture", this.file);
      if (this.file !== null) {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .post("partenaires/upload", formDataPicture)
          .then((response) => {
            this.picture_name = response.data;
            const formData = {
              email: this.email,
              phone: this.phone,
              address: this.address,
              website: this.website,
              picture: this.picture_name,
              name: this.name,
              city: this.city,
              zip: this.zip,
            };

            axios
              .create({
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .put("partenaires/" + id, formData)
              .then((response) => {
                const index = this.partenaires.findIndex((obj) => obj.id == id);
                this.partenaires[index] = response.data;
                this.initializeData();
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formData = {
          email: this.email,
          phone: this.phone,
          address: this.address,
          website: this.website,
          picture: this.picture,
          name: this.name,
          city: this.city,
          zip: this.zip,
        };

        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .put("partenaires/" + id, formData)
          .then((response) => {
            const index = this.partenaires.findIndex((obj) => obj.id == id);
            this.partenaires[index] = response.data;
            this.initializeData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("partenaires")
      .then((response) => {
        this.partenaires = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.partenaire-admin {
  .table {
    .image {
      img {
        max-height: 50px;
        width: auto;
      }
    }
  }

  .field {
    .image {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
}
</style>
