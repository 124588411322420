<template>
  <div class="convocation-item">
    <div class="card">
      <div class="card-content" v-if="convocation.game">
        <div class="media">
          <div class="media-content">
            <p class="title is-3">{{ convocation.team.name }}</p>
            <p class="subtitle is-4">
              {{ convocation.game.type.icon }} {{ convocation.game.type.name }}
            </p>
          </div>
          <div class="media-right">
            <span class="icon">
              <img
                src="@/assets/icons/house.svg"
                alt="Domicile"
                v-if="convocation.game.is_home"
              />
            </span>
            <span class="icon">
              <img
                src="@/assets/icons/plane-solid.svg"
                alt="Exterieur"
                v-if="!convocation.game.is_home"
              />
            </span>
          </div>
        </div>

        <div class="media">
          <div class="media-left">
            <p class="subtitle is-6">Date :</p>
            <p class="subtitle is-6">Heure de rendez-vous :</p>
            <p class="subtitle is-6">Adversaire :</p>
            <p class="subtitle is-6">Heure de match :</p>
            <p class="subtitle is-6" v-if="convocation.game.place != null">
              Lieu du match :
            </p>
            <p class="subtitle is-6" v-if="convocation.responsable != null">
              Responsable
            </p>
            <p
              class="subtitle is-6"
              v-if="
                convocation.responsable != null &&
                convocation.responsable.phone != null
              "
            >
              Téléphone
            </p>
          </div>
          <div class="media-content">
            <p class="title is-6">{{ dateFormat(convocation.game.date) }}</p>
            <p class="title is-6">{{ convocation.appointment }}</p>
            <p class="title is-6">{{ convocation.game.opponent }}</p>
            <p class="title is-6">{{ convocation.game.hour }}</p>
            <p class="title is-6" v-if="convocation.game.place != null">
              {{ convocation.game.place }}
            </p>
            <p class="title is-6" v-if="convocation.responsable != null">
              {{ convocation.responsable.name }}
              {{ convocation.responsable.firstname }}
            </p>
            <p
              class="title is-6"
              v-if="
                convocation.responsable != null &&
                convocation.responsable.phone != null
              "
            >
              {{ convocation.responsable.phone }}
            </p>
          </div>
        </div>

        <div class="media media-table">
          <table class="table">
            <thead class="table-head">
              <tr>
                <th>#</th>
                <th class="player">Joueur</th>
                <th class="car" v-if="convocation.category.has_drivers">
                  <img src="@/assets/icons/car-solid.svg" alt="voiture" />
                </th>
                <th class="shirt" v-if="convocation.category.has_shirts">
                  <img src="@/assets/icons/soccer-jersey-2.png" alt="maillot" />
                </th>
                <th class="clean" v-if="convocation.category.has_cleaners">
                  <img src="@/assets/icons/broom-solid.svg" alt="nettoyage" />
                </th>
              </tr>
            </thead>
            <tbody
              v-for="convocation_player in convocation.convocation_players"
              :key="convocation_player.id"
              class="table-body"
            >
              <tr>
                <th class="number">
                  {{
                    getIndex(
                      convocation.convocation_players,
                      convocation_player
                    )
                  }}
                </th>
                <td>
                  {{ convocation_player.player.name }}
                  {{ convocation_player.player.firstname }}
                </td>
                <td class="car" v-if="convocation.category.has_drivers">
                  <img
                    src="@/assets/icons/check-solid.svg"
                    alt="voiture"
                    v-if="convocation_player.is_driver"
                  />
                </td>
                <td class="shirt" v-if="convocation.category.has_shirts">
                  <img
                    src="@/assets/icons/check-solid.svg"
                    alt="maillot"
                    v-if="convocation_player.is_shirt"
                  />
                </td>
                <td class="clean" v-if="convocation.category.has_cleaners">
                  <img
                    src="@/assets/icons/check-solid.svg"
                    alt="nettoyage"
                    v-if="convocation_player.is_cleaner"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-content" v-if="!convocation.game">
        <div class="media">
          <div class="media-content">
            <p class="title is-3" style="text-align: center">
              {{ convocation.team.name }}
            </p>
          </div>
        </div>

        <div
          class="media"
          style="
            justify-content: center;
            align-items: center;
            flex-flow: column;
          "
        >
          <p class="subtitle is-5">Pas de match le :</p>
          <p class="title is-6">{{ dateFormat(convocation.date) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["convocation"],
  methods: {
    getIndex(players, player) {
      return players.indexOf(player) + 1;
    },
    dateFormat(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var newDate = new Date(date);
      newDate = newDate.toLocaleDateString("fr-FR", options);
      return newDate;
    },
  },
};
</script>

<style lang="scss">
.convocation-item {
  .card {
    .card-content {
      .media {
        .media-content {
          overflow-x: visible;
        }
        .media-right {
          align-self: center;
        }
      }
      .media-table {
        justify-content: center;
        width: 100%;
        .table {
          .table-head {
            clean,
            .car,
            .shirt {
              padding-left: 5px;
              padding-right: 5px;
            }
          }
          .table-body,
          .table-head {
            .number {
              background-color: #e75913;
              border-bottom: solid #ffffff;
              border-radius: 0;
              width: 20%;
            }

            .clean,
            .car,
            .shirt {
              text-align: center;
              padding-left: 5px;
              padding-right: 5px;
              img {
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
