<!-- <template>
  <div class="boutique">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <p class="title">Boutique</p>
          </div>
        </div>
      </section>
    </div>
    <div class="boutique-body">
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="columns is-multiline liste">
            <div
              class="column is-one-third"
              v-for="element in elements"
              :key="element"
            >
              <BoutiqueComponent
                :element="element"
                @addToBasket="ajoutPanier"
              />
            </div>
          </div>
        </div>
        <div class="column is-one-third is-narrow">
          <PanierComponent :panier="panier" @remove="removeFromPanier" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoutiqueComponent from "../components/boutique/BoutiqueComponent.vue";
import PanierComponent from "../components/boutique/PanierComponent.vue";
export default {
  name: "BoutiqueView",
  components: { BoutiqueComponent, PanierComponent },
  data() {
    return {
      elements: {
      },
      panier: [],
    };
  },
  methods: {
    ajoutPanier(item) {
      this.panier.push(item);
    },
    removeFromPanier(item) {
      this.panier = this.panier.filter(
        (data) => data.element.img != item.element.img
      );
    },
  },
};
</script>

<style lang="scss" scoped>
//hero part

.boutique {
  .hero {
    text-align: center;
    p {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/boutique2.jpg");
    }
    .title {
      color: #000000;
    }
  }
  .boutique-body {
    margin-bottom: 20px;
    height: 100%;
  }

  .liste {
    width: 100%;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .panier-container {
    //position: sticky;
  }
}
</style> -->
<template>
  <div class="boutique">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="title">Boutique</h3>
          </div>
        </div>
      </section>
    </div>
    <div class="boutique-body">
      <h5 class="content">
        Représenter fièrement les couleurs de notre club !
      </h5>
      <!-- <button class="download" @click="downloadBonCommande()">
        Télécharger ici le bon de commande
      </button> -->
    </div>
    <iframe
      id="haWidget"
      allowtransparency="true"
      scrolling="auto"
      src="https://www.helloasso.com/associations/saint-cyr-herbignac-football/boutiques/boutique-sch/widget"
      style="
        width: 94%;
        height: 750px;
        margin-top: 10px;
        border: none;
        margin-left: 3%;
      "
    ></iframe>

    <!-- <div class="picture">
      <figure class="image">
        <img src="@/assets/boutique/boutique1.png" alt="Boutique du club" />
      </figure>
    </div>
    <div class="picture">
      <figure class="image">
        <img src="@/assets/boutique/boutique2.png" alt="Boutique du club" />
      </figure>
    </div>-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  methods: {
    downloadBonCommande() {
      axios
        .post(
          "public/boutique/download",
          {
            file_name: "Bon de commande SCH 2022_2023.xlsx",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Bon de commande SCH 2022_2023.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>

<style lang="scss">
.boutique {
  height: 100%;

  .hero {
    text-align: center;
    h3 {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/boutique2.jpg");
    }
    .title {
      color: #000000;
    }
  }

  .picture {
    margin-bottom: 20px;
    margin-top: 30px;
    text-align: -webkit-center;

    .image {
      max-width: 90%;
      max-height: 60%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 20px;
      }
    }
  }

  .boutique-body {
    text-align: -webkit-center;

    .download {
      color: #000000;
      background-color: #ec8a5a;
      border-radius: 10px;
      border: 0;
      cursor: pointer;
      font-weight: bold;
      padding: 5px;
    }

    .content {
      width: 80%;
      font-size: large;
    }
  }
}
</style>
