<template>
  <div>
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter une équipe
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Division</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="team in teams" :key="team.id">
          <td>{{ team.name }}</td>
          <td>{{ team.league }}</td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(team.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone de crayon"
                />
              </div>
            </button>

            <button class="button is-danger" @click="lauchDelete(team.id)">
              <div class="icon">
                <img
                  src="@/assets/icons/trash-can-solid.svg"
                  alt="icone de poubelle"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="teamName != null">
            {{ teamName }}
          </p>
          <p class="modal-card-title" v-if="teamName == null">
            Ajouter une équipe
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="field">
                  <label class="label">Nom</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Nom"
                      v-model="name"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="label">Catégorie</label>
                  <div class="select">
                    <select v-model="category">
                      <option
                        v-for="category in categories"
                        :key="category.id"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Division</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Division"
                      v-model="league"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            v-if="is_create == true"
            @click="add()"
          >
            Ajouter cette catégorie
          </button>
          <button
            class="button is-warning"
            v-if="is_create == false"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="close">Annuler</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="teamActif.name != null">
            {{ teamActif.name }}
          </p>
          <p class="modal-card-title" v-if="teamActif.name == null">
            Ajouter une catégorie
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer cette catégorie ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="close">Annuler</button>
          <button
            class="button is-danger"
            v-if="teamActif.name != null"
            @click="deleteCategory(teamActif)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: function () {
    return {
      teams: [],
      categories: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      teamActif: [],
      teamName: null,
      is_create: false,
      id: null,
      name: null,
      category: null,
      league: null,
      error_messsage: null,
      is_failure: false,
    };
  },
  methods: {
    initializeData() {
      (this.teamName = null),
        (this.is_create = false),
        (this.id = null),
        (this.name = null),
        (this.category = null),
        (this.league = false),
        (this.teamActif = []),
        (this.isActiveModalUpdate = false),
        (this.error_messsage = null),
        (this.is_failure = false),
        (this.isActiveModalDelete = false);
    },
    close() {
      this.initializeData();
    },
    checkRequired() {
      if (this.name == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner un nom.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.category == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez sélectionner une catégorie.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }
      return true;
    },

    //Partie ajout
    lauchCreate() {
      this.isActiveModalUpdate = true;
      this.is_create = true;
    },
    add() {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        name: this.name,
        category: this.category,
        league: this.league,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .post("teams", formData)
        .then((response) => {
          this.teams.push(response.data);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //partie modification
    lauchUpdate(id) {
      this.is_create = false;
      this.isActiveModalUpdate = true;

      var proxy = { ...this.teams.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      (this.teamName = target_copy.name),
        (this.id = target_copy.id),
        (this.name = target_copy.name),
        (this.category = target_copy.category.id),
        (this.league = target_copy.league);
    },
    update(id) {
      const formData = {
        name: this.name,
        category: this.category,
        league: this.league,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .put("teams/" + id, formData)
        .then((response) => {
          const index = this.teams.findIndex((obj) => obj.id == id);
          this.teams[index] = response.data;
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //partie suppression
    lauchDelete(id) {
      this.is_create = false;

      var proxy = { ...this.teams.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.teamActif = target_copy;
      this.isActiveModalDelete = true;
    },
    deleteCategory(team) {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .delete("teams/" + team.id)
        .then(() => {
          const index = this.teams.indexOf(team);
          this.teams.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("teams")
      .then((response) => {
        this.teams = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("categories")
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss"></style>
