import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import OrganigrammeView from "../views/OrganigrammeView";
import Boutiqueview from "../views/BoutiqueView";
import ActualiteView from "../views/ActualiteView";
import ContactView from "../views/ContactView";
import PartenaireView from "../views/PartenaireView";
import LoginView from "../views/LoginView";
import AdministrationView from "../views/AdministrationView";
import ConvocationView from "../views/ConvocationView";
import FirstConnexionView from "../views/FirstConnexionView";
import PhototequeView from "../views/PhototequeView";
import ArticleDetailView from "../views/ArticleDetailView";
import CGUView from "@/views/CGUView.vue";
import MentionsLegalesView from "@/views/MentionsLegalesView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/organigramme",
    name: "organigramme",
    component: OrganigrammeView,
  },
  {
    path: "/boutique",
    name: "boutique",
    component: Boutiqueview,
  },
  {
    path: "/phototeque",
    name: "phototeque",
    component: PhototequeView,
  },
  {
    path: "/actualite",
    name: "actualite",
    component: ActualiteView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/partenaire",
    name: "partenaire",
    component: PartenaireView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/firstconnexion",
    name: "firstconnexion",
    component: FirstConnexionView,
  },
  {
    path: "/administration/:choosenComponent",
    name: "administration",
    component: AdministrationView,
    props: true,
  },
  {
    path: "/convocations",
    name: "convocations",
    component: ConvocationView,
  },
  {
    path: "/cgu",
    name: "cgu",
    component: CGUView,
  },
  {
    path: "/mention-legales",
    name: "mention-legales",
    component: MentionsLegalesView,
  },
  {
    path: "/article/:id",
    name: "details",
    component: ArticleDetailView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
