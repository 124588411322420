<template>
  <div>
    <div class="part">
      <div class="title is-2">Bureau</div>
      <div class="columns is-multiline is-mobile office">
        <div
          class="column is-one-quarter-desktop is-half-mobile member"
          v-for="member in members"
          :key="member.id"
        >
          <div class="card">
            <div class="card-image">
              <figure class="image" v-if="member.picture">
                <img
                  class="picture-person"
                  :src="baseURL + member.picture"
                  alt="image de la personne"
                />
              </figure>
              <figure class="image is-96x96" v-if="!member.picture">
                <img
                  class="picture"
                  src="@/assets/icons/user-solid.svg"
                  alt="icone personne"
                />
              </figure>
            </div>
            <div class="card-content organigramme-card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-size-5">
                    {{ member.name }} {{ member.firstname }}
                  </p>
                  <p class="subtitle is-size-6">
                    {{ member.bureau_fonction }}
                  </p>
                </div>
              </div>

              <div class="content">
                <div class="details media-content">
                  <img
                    class="image is-16x16"
                    src="@/assets/icons/phone.svg"
                    alt="phone"
                  />
                  <p v-if="member.phone != null" class="subtitle is-size-7">
                    {{ member.phone }}
                  </p>
                  <p v-if="member.phone == null" class="subtitle is-size-7">
                    Inconnu
                  </p>
                </div>
                <div class="details">
                  <img
                    class="image is-16x16"
                    src="@/assets/icons/mail.svg"
                    alt="mail"
                  />
                  <p v-if="member.email != null" class="subtitle is-size-7">
                    {{ member.email }}
                  </p>
                  <p v-if="member.email == null" class="subtitle is-size-7">
                    Inconnue
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part dirigeant">
      <div class="title is-2">Dirigeant</div>

      <div class="columns is-multiline is-mobile office">
        <div
          class="column is-one-quarter-desktop is-half-mobile member"
          v-for="member in dirigeants"
          :key="member.id"
        >
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by5" v-if="member.picture">
                <img
                  :src="baseURL + member.picture"
                  alt="image de la personne"
                />
              </figure>
              <figure class="image is-96x96" v-if="!member.picture">
                <img
                  class="picture"
                  src="@/assets/icons/user-solid.svg"
                  alt="icone personne"
                />
              </figure>
            </div>
            <div class="card-content organigramme-card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-size-5">
                    {{ member.name }} {{ member.firstname }}
                  </p>
                  <p
                    class="subtitle is-size-6"
                    v-if="member.coach_category != null"
                  >
                    {{ member.coach_category.name }}
                  </p>
                </div>
              </div>

              <div class="content">
                <div class="details media-content">
                  <img
                    class="image is-16x16"
                    src="@/assets/icons/phone.svg"
                    alt="phone"
                  />
                  <p v-if="member.phone != null" class="subtitle is-size-7">
                    {{ member.phone }}
                  </p>
                  <p v-if="member.phone == null" class="subtitle is-size-7">
                    Inconnu
                  </p>
                </div>
                <div class="details">
                  <img
                    class="image is-16x16"
                    src="@/assets/icons/mail.svg"
                    alt="mail"
                  />
                  <p v-if="member.email != null" class="subtitle is-size-7">
                    {{ member.email }}
                  </p>
                  <p v-if="member.email == null" class="subtitle is-size-7">
                    Inconnue
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part">
      <div class="title is-2">Bénévoles</div>

      <div class="columns is-multiline is-mobile office">
        <div
          class="column is-one-quarter-desktop is-half-mobile member"
          v-for="member in benevoles"
          :key="member.id"
        >
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by5" v-if="member.picture">
                <img
                  :src="baseURL + member.picture"
                  alt="photo de la personne"
                />
              </figure>
              <figure class="image is-96x96" v-if="!member.picture">
                <img
                  class="picture"
                  src="@/assets/icons/user-solid.svg"
                  alt="icone personne"
                />
              </figure>
            </div>
            <div class="card-content organigramme-card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-size-5">
                    {{ member.name }} {{ member.firstname }}
                  </p>
                  <p class="subtitle is-size-6">
                    {{ member.bureau_fonction }}
                  </p>
                </div>
              </div>

              <div class="content">
                <div class="details media-content">
                  <img
                    class="image is-16x16"
                    src="@/assets/icons/phone.svg"
                    alt="phone"
                  />
                  <p v-if="member.phone != null" class="subtitle is-size-7">
                    {{ member.phone }}
                  </p>
                  <p v-if="member.phone == null" class="subtitle is-size-7">
                    Inconnu
                  </p>
                </div>
                <div class="details">
                  <img
                    class="image is-16x16"
                    src="@/assets/icons/mail.svg"
                    alt="mail"
                  />
                  <p v-if="member.email != null" class="subtitle is-size-7">
                    {{ member.email }}
                  </p>
                  <p v-if="member.email == null" class="subtitle is-size-7">
                    Inconnue
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["members", "dirigeants", "benevoles"],
  data() {
    return {
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
    };
  },
};
</script>

<style lang="scss">
.dirigeant {
  background-color: #ffffff;
}
.part {
  width: 100%;
  padding: 20px 5%;
  .title {
    color: #e75913;
  }

  .organigramme-card-content {
    padding-left: 0;
    padding-right: 0;
  }
  .office {
    display: flex;
    margin-bottom: 20px;
    color: #000000;
    place-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 30px;
    text-align: -webkit-center;

    .card {
      padding: 10px;
    }

    .picture {
      max-height: 90px;
    }

    .picture-person {
      width: 100%;
      aspect-ratio: 1/2;
      object-fit: contain;
    }

    .details {
      display: flex;
      justify-content: center;
      width: 90%;

      img {
        margin-right: 15px;
      }
    }
  }
}
</style>
