<template>
  <section class="hero is-primary is-fullheight login">
    <div class="hero-body">
      <div class="login-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-10-tablet is-10-desktop is-10-widescreen">
              <div class="box">
                <div class="field">
                  <label for="" class="label">Email</label>
                  <div class="control has-icons-left">
                    <input
                      type="text"
                      name="email"
                      v-model="email"
                      placeholder="email@hotmail.com"
                      class="input"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label for="" class="label">Password</label>
                  <div class="control has-icons-left">
                    <input
                      v-model="password"
                      type="password"
                      placeholder="*******"
                      class="input"
                      name="password"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <button
                    type="submit"
                    class="button is-login"
                    @click="submitForm()"
                  >
                    Connexion
                  </button>
                  <button
                    class="button is-login is-first-connexion"
                    @click="sendEmail()"
                  >
                    Première connexion
                  </button>
                </div>
                <div class="field forgot-password">
                  <a @click="sendEmail()">Mot de passe oublié ?</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="notification is-success is-light" v-if="is_success">
          {{ success_message }}
        </div>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: null,
      password: null,
      is_success: false,
      is_failure: false,
      error_messsage: null,
      success_message: null,
    };
  },
  methods: {
    checkRequired() {
      if (this.email == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez remplir le champ email";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }
      return true;
    },
    submitForm() {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        email: this.email,
        password: this.password,
      };

      axios
        .post("public/login", formData)
        .then((response) => {
          const token = response.data.access_token;
          const abilities = response.data.abilities;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Bearer"] = token;
          localStorage.setItem("token", token);
          localStorage.setItem("abilities", abilities);
          if (token != null) {
            this.is_success = true;
            this.success_message =
              "Vous êtes connecté.\nVous allez être redirigé vers la page d'accueil.";
            setTimeout(() => {
              this.is_success = false;
              this.$router.push({ name: "home" });
            }, 1000);
          } else {
            this.is_failure = true;
            this.error_messsage = response.data.message;
            localStorage.setItem("token", "null");
            localStorage.setItem("abilities", "null");
            setTimeout(() => {
              this.is_failure = false;
              this.error_messsage = null;
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendEmail() {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        email: this.email,
      };

      axios
        .post("public/firstconnexion", formData)
        .then((response) => {
          this.is_success = true;
          this.success_message = response.data;
          setTimeout(() => {
            this.is_success = false;
          }, 3000);
        })
        .catch((error) => {
          this.is_failure = true;
          this.error_messsage = error.response.data.message;
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
        });
    },
  },
};
</script>
<style lang="scss">
.login {
  .login-body {
    display: block;
    width: 100%;

    .notification {
      margin-top: 15px;
    }

    .field {
      .is-login {
        background-color: #e75913;
        color: #ffffff;
      }
    }
    .forgot-password {
      color: #e75913;
      text-decoration: underline;
    }
  }
}
</style>
