<template>
  <div>
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter un rôle
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Nombre d'abilitations</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="role in roles" :key="role.id">
          <td>{{ displayName(role.name) }}</td>
          <td>{{ countAbilities(role.abilities) }}</td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(role.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone de crayon"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="!is_create">
            {{ roleName }}
          </p>
          <p class="modal-card-title" v-if="is_create">Ajouter un rôle</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-12-tablet is-12-desktop is-12-mobile">
              <form @submit.prevent="submitForm" class="box">
                <div class="field">
                  <label class="label">Nom</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Nom"
                      v-model="name"
                    />
                  </div>
                </div>

                <div class="columns is-multiline">
                  <div
                    v-for="ability in abilities"
                    :key="ability.id"
                    class="checkbox-container"
                  >
                    <div class="column is-12-desktop is-12-mobile is-12-tablet">
                      <div class="control" style="display: flex">
                        <label class="label">{{ ability.name }}</label>
                        <input
                          style="margin-left: 10px"
                          type="checkbox"
                          :checked="currentRoleAbilities.includes(ability.name)"
                          @change="addAbility(ability.name)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            v-if="is_create == true"
            @click="add()"
          >
            Ajouter ce rôle
          </button>
          <button
            class="button is-warning"
            v-if="is_create == false"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="close">Annuler</button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: function () {
    return {
      roles: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      roleActif: [],
      roleName: null,
      is_create: false,
      id: null,
      name: null,
      currentRoleAbilities: [],
      abilities: [],
      error_messsage: null,
      is_failure: false,
    };
  },
  methods: {
    initializeData() {
      (this.roleName = null),
        (this.is_create = false),
        (this.id = null),
        (this.name = null),
        (this.roleActif = []),
        (this.currentRoleAbilities = []),
        (this.error_messsage = null),
        (this.is_failure = false),
        (this.isActiveModalUpdate = false),
        (this.isActiveModalDelete = false);
    },
    close() {
      this.initializeData();
    },
    addAbility(name) {
      if (this.currentRoleAbilities.includes(name)) {
        const index = this.currentRoleAbilities.findIndex(
          (obj) => obj.name == name
        );
        this.currentRoleAbilities.splice(index, 1);
      } else {
        this.currentRoleAbilities.push(name);
      }
    },
    displayName(name) {
      if (name.length > 10) {
        return name.substr(0, 10) + "...";
      }
      return name;
    },
    countAbilities(abilities) {
      return abilities.length;
    },
    checkRequired() {
      if (this.name == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner un nom.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }
      return true;
    },
    //Partie ajout
    lauchCreate() {
      this.isActiveModalUpdate = true;
      this.is_create = true;
    },
    add() {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        name: this.name,
        title: null,
        abilities: this.currentRoleAbilities,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .post("roles", formData)
        .then((response) => {
          this.roles.push(response.data);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //partie modification
    lauchUpdate(id) {
      this.is_create = false;
      this.isActiveModalUpdate = true;

      var proxy = { ...this.roles.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      (this.roleName = target_copy.title),
        (this.id = target_copy.id),
        (this.name = target_copy.name);

      target_copy.abilities.forEach((ability) => {
        this.currentRoleAbilities.push(ability.name);
      });
    },
    update(id) {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        name: this.name,
        title: null,
        abilities: this.currentRoleAbilities,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .put("roles/" + id, formData)
        .then((response) => {
          const index = this.roles.findIndex((obj) => obj.id == id);
          this.roles[index] = response.data;
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("roles")
      .then((response) => {
        this.roles = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("abilities")
      .then((response) => {
        this.abilities = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss"></style>
