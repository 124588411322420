<template>
  <div class="home">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="subtitle">Saint Cyr Herbignac Football</h3>
            <h3 class="title">SITE OFFICIEL</h3>
          </div>
        </div>
      </section>
      <!-- <div class="birthday">
        <img src="@/assets/icons/birthday.svg" alt="birthday" />
        Aucun Anniversaire
      </div> -->
    </div>
    <div class="home-body">
      <div class="articles">
        <div class="tile is-parent">
          <div class="tile is-parent is-parent-actualites">
            <article class="tile is-child actualites">
              <div class="content">
                <p class="title tile-title">Actualités</p>
                <div class="content" v-for="article in articles" :key="article">
                  <HomeActuComponent :article="article" />
                </div>
                <a class="button" @click="redirectActualites()">Voir plus</a>
              </div>
            </article>
          </div>
          <div class="tile is-vertical is-8">
            <div class="tile tile-left-part">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p class="title">Dernier résultats</p>
                  <div class="columns is-multiline is-mobile">
                    <div
                      class="content column is-one-half-mobile is-one-third-desktop"
                      v-for="game in lastGames"
                      :key="game"
                    >
                      <HomeGameComponent :game="game" />
                    </div>
                  </div>
                </article>
                <article class="tile is-child">
                  <p class="title">Matchs à venir</p>
                  <div class="columns is-multiline is-mobile">
                    <div
                      class="content column is-one-half-mobile is-one-third-desktop"
                      v-for="game in nextGames"
                      :key="game"
                    >
                      <HomeGameComponent :game="game" />
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navigation columns is-mobile">
        <a @click="redirectOrganigramme()">
          <figure class="image column">
            <img
              src="./../assets/home/navigation/organigramme.jpg"
              alt="organigramme"
            />
          </figure>
        </a>
        <a @click="redirectBoutique()">
          <img
            class="image column"
            src="./../assets/home/navigation/boutique.jpg"
            alt="boutique"
          />
        </a>
        <a @click="redirectPhototeque()">
          <img
            class="image column"
            src="./../assets/home/navigation/phototeque.jpg"
            alt="phototeque"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import HomeActuComponent from "@/components/home/HomeActuComponent.vue";
import HomeGameComponent from "../components/home/HomeGameComponent.vue";

export default {
  name: "HomeView",
  components: {
    HomeActuComponent,
    HomeGameComponent,
  },
  data: function () {
    return {
      lastGames: [],
      nextGames: [],
      articles: [],
      pictures: {},
      partenaires: {},
      games: [],
    };
  },
  methods: {
    redirectActualites() {
      this.$router.push({ name: "actualite" });
    },
    redirectPhototeque() {
      this.$router.push({ name: "phototeque" });
    },
    redirectOrganigramme() {
      this.$router.push({ name: "organigramme" });
    },
    redirectBoutique() {
      this.$router.push({ name: "boutique" });
    },
  },
  beforeMount() {
    axios
      .get("public/last")
      .then((response) => {
        this.lastGames = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("public/next")
      .then((response) => {
        this.nextGames = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("public/articles/last")
      .then((response) => {
        this.articles = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
.header {
  margin-bottom: 15px;
}

.hero {
  &::before {
    background-image: url("@/assets/hero/home.jpg");
  }
}

.birthday {
  z-index: 1;
  position: relative;
  background-color: #ffffff;
  min-height: 100px;
  margin: -60px 10% 0 auto;
  width: 20%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    margin-right: 10%;
  }
}
.home-body {
  text-align: -webkit-center;
  //home page disposition
  .articles {
    text-align: center;
    width: 100%;

    .tile-left-part {
      background-color: #f7f0f0;
    }

    .is-parent-actualites {
      background-color: #332f2e;
      border-radius: 15px;
      .actualites {
        .content {
          text-align: -webkit-center;
          .tile-title {
            color: #ffffff;
          }
        }
      }
    }

    .button {
      background-color: #e75913;
      color: white;
      width: 300px;
      border-radius: 15px;
    }
  }

  .navigation {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #ec8a5a;
    gap: 0.25rem;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0;
  }

  .newsletter {
    margin-top: 10px;

    .hero {
      .hero-body {
        text-align: center;
      }
      h3 {
        margin-left: 0;
      }
      .field {
        padding: 0 25%;
        .input {
          width: 100%;
        }
      }
    }
    .title {
      color: #ffffff;
    }
  }
}
</style>
