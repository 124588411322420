<template>
  <div>
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter un évènement
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Titre</th>
          <th>Nombre de tags</th>
          <th>Date</th>
          <th>Nombre de fichiers / images</th>
          <th>Modification</th>
          <th>Suppression</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.id">
          <td>{{ event.title }}</td>
          <td>{{ event.tags.length }}</td>
          <td>{{ dateFormat(event.date) }}</td>
          <td>{{ event.files.length }}</td>
          <td>
            <button class="button is-warning" @click="lauchUpdate(event.id)">
              Modification
            </button>
          </td>
          <td>
            <button
              class="button is-danger is-outlined"
              @click="lauchDelete(event.id)"
            >
              Suppression
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="closeUpdate"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="eventTitle != null">
            {{ eventTitle }}
          </p>
          <p class="modal-card-title" v-if="eventTitle == null">
            Ajouter un évènement
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeUpdate"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="field">
                  <label class="label">Titre</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Titre"
                      v-model="title"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Date</label>
                  <div class="control">
                    <input
                      class="input"
                      type="date"
                      placeholder="Date"
                      v-model="date"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Tags</label>
                  <div class="control tags columns is-multiline">
                    <!-- checkbox -->
                    <label
                      class="checkbox column is-3"
                      v-for="tag in tags"
                      :key="tag.id"
                    >
                      <input type="checkbox" :name="tag.name" />
                      <div
                        class="box"
                        @click="updateTags(tag.name)"
                        v-bind:class="{
                          isSelected: this.eventTags.includes(tag.name),
                        }"
                      >
                        {{ tag.name }}
                      </div>
                    </label>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Images / Fichiers</label>
                  <div class="file has-name is-boxed">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="resume"
                        @change="updateFiles"
                        multiple
                      />
                      <span class="file-cta">
                        <span class="file-label">
                          Choisissez un ou plusieurs fichiers
                        </span>
                      </span>
                    </label>
                  </div>
                  <div
                    class="columns is-multiline"
                    v-for="file in files"
                    :key="file"
                  >
                    <div class="box column">
                      <p>
                        {{ file.name }}
                      </p>
                      <button class="delete" @click="deleteFile(file)"></button>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Description / Message</label>
                  <div class="control">
                    <textarea
                      class="textarea"
                      placeholder="Message"
                      name="desription"
                      v-model="description"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            v-if="eventTitle == null"
            @click="add()"
          >
            Ajouter cet évènement
          </button>
          <button
            class="button is-warning"
            v-if="eventTitle != null"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="closeUpdate">Cancel</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="closeDelete"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="eventActif.title != null">
            {{ eventActif.title }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeDelete"
          ></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer cet évènement ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="closeDelete">Cancel</button>
          <button
            class="button is-danger"
            v-if="eventActif.title != null"
            @click="deleteEvent(eventActif.id)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      type: "results",
      files: [],
      tags: [
        {
          id: 1,
          name: "Séniors A",
        },
        {
          id: 2,
          name: "Séniors B",
        },
        {
          id: 3,
          name: "Séniors C",
        },
        {
          id: 4,
          name: "U18 A",
        },
        {
          id: 5,
          name: "u18 A",
        },
        {
          id: 6,
          name: "U17",
        },
        {
          id: 7,
          name: "U15 A",
        },
        {
          id: 8,
          name: "U15 B",
        },
      ],
      events: [
        {
          id: 1,
          title: "Bourriche",
          date: "2022-06-23",
          begin: "2022-06-23",
          end: "2022-06-23",
          tags: ["u18", "Séniors A", "Informations", "Evenement"],
          files: ["Image 1"],
          description:
            "Bourriche du club cpour la rencontre contre alerte de Méan. Tarification  : 5 € les 3 ou 2 € le ticket.",
        },
      ],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      eventActif: {},
      eventTitle: null,
      id: null,
      title: null,
      date: null,
      begin: null,
      end: null,
      eventTags: [],
      eventfiles: [],
      description: null,
    };
  },
  methods: {
    updateFiles(event) {
      const filesList = event.target.files;
      for (var i = 0; i < filesList.length; i++) {
        this.files.push(filesList[i]);
      }
    },
    deleteFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },
    updateTags(value) {
      if (this.eventTags.includes(value)) {
        const index = this.eventTags.indexOf(value);
        if (index > -1) {
          this.eventTags.splice(index, 1);
        }
      } else {
        this.eventTags.push(value);
      }
    },
    lauchCreate() {
      this.isActiveModalUpdate = true;
    },
    lauchDelete(id) {
      var proxy = { ...this.events.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.eventActif = target_copy;
      this.isActiveModalDelete = true;
    },
    lauchUpdate(id) {
      var proxy = { ...this.events.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      (this.eventTitle = target_copy.title),
        (this.id = target_copy.id),
        (this.title = target_copy.title),
        (this.date = target_copy.date),
        (this.eventTags = target_copy.tags),
        (this.eventfiles = target_copy.files),
        (this.begin = target_copy.begin),
        (this.end = target_copy.end),
        (this.description = target_copy.description);
      this.isActiveModalUpdate = true;
    },
    closeUpdate() {
      this.isActiveModalUpdate = false;
      (this.eventTitle = null),
        (this.id = null),
        (this.title = null),
        (this.date = null),
        (this.eventTags = []),
        (this.eventfiles = []),
        (this.begin = null),
        (this.end = null),
        (this.description = null);
    },
    closeDelete() {
      this.isActiveModalDelete = false;
      this.eventActif = {};
    },
    add() {},
    update() {},
    deleteEvent() {
      this.isActiveModalDelete = false;
      this.eventActif = {};
    },
    dateFormat(value) {
      var tempDate = new Date(value);
      return [
        tempDate.getDate(),
        tempDate.getMonth() + 1,
        tempDate.getFullYear(),
      ].join("/");
    },
  },
};
</script>
<style lang="scss">
.types {
  label {
    input {
      display: none;
    }
  }
  .isActive {
    border: 3px solid #e75913;
    border-radius: 30px;
  }
}

.tags {
  label {
    input {
      display: none;
    }
  }
  .isSelected {
    background-color: #e75913;
    color: #ffffff;
  }
}
</style>
