<template>
  <div class="contact-formulaire">
    <div class="field-title">
      <label class="title">Contactez Nous !</label>
    </div>
    <br />
    <br />

    <div class="columns">
      <div class="field column is-half">
        <label class="label">Nom</label>
        <div class="control">
          <input class="input" type="text" placeholder="Nom" />
        </div>
      </div>
      <div class="field column is-half">
        <label class="label">Prénom</label>
        <div class="control">
          <input class="input" type="text" placeholder="Prénom" />
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input class="input" type="email" placeholder="Email" />
      </div>
    </div>

    <div class="field">
      <label class="label">Message</label>
      <div class="control">
        <textarea class="textarea" placeholder="Votre message"></textarea>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" />
          J'accepte les <a href="#">termes et conditions</a>
        </label>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button class="button is-success">Envoyer</button>
      </div>
      <div class="control">
        <button class="button is-danger is-light">Annuler</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.contact-formulaire {
  margin-top: 10px;
  .field-title {
    text-align: center;
  }
  .field {
    .is-success {
      background-color: #e75913;
    }
  }
}
</style>
