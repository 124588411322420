<template>
  <div>
    <div class="match-header">
      <button class="button is-success" @click="lauchCreate()">
        Ajouter un match
      </button>
      <div class="field">
        <label>Seulement match en cours ?</label>
        <input
          type="checkbox"
          checked
          v-model="displayOnlyCurrent"
          @change="filterGames()"
        />
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Match</th>
          <th>Date</th>
          <th>Score</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="game in displayGames" :key="game.id">
          <td v-if="game.is_home">
            <div class="is-size-7">
              {{ game.team.name }}
            </div>
            <div class="is-size-7">
              {{ game.opponent }}
            </div>
          </td>
          <td v-if="!game.is_home">
            <div class="is-size-7">
              {{ game.opponent }}
            </div>
            <div class="is-size-7">
              {{ game.team.name }}
            </div>
          </td>
          <!-- <td>{{ game.opponent }}</td> -->
          <td class="is-size-7">
            {{ dateFormat(game.date) }}
          </td>
          <td v-if="!game.is_finish">-</td>
          <td v-if="game.is_home && game.is_finish">
            <div>
              {{ game.sch_goals }}
            </div>
            <div>
              {{ game.opponent_goals }}
            </div>
          </td>
          <td v-if="!game.is_home && game.is_finish">
            <div>
              {{ game.opponent_goals }}
            </div>
            <div>
              {{ game.sch_goals }}
            </div>
          </td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(game.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone de crayon"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="!is_create">
            {{ currentMatch }}
          </p>
          <p class="modal-card-title" v-if="is_create">Ajouter un match</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-12-mobile is-12-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="columns">
                  <div class="field column is-5">
                    <label class="label">Date</label>
                    <div class="control">
                      <input
                        @change="changeDate($event)"
                        class="input"
                        type="date"
                        placeholder="Date"
                        v-model="date"
                      />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Equipe Herbignac</label>
                  <div class="select">
                    <select v-model="team" name="team">
                      <option
                        :value="team.id"
                        v-for="team in teams"
                        :key="team.id"
                      >
                        {{ team.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Adversaire</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="text"
                      v-model="opponent"
                      id="opponent"
                      placeholder="opponent"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Heure du match</label>
                  <div class="control">
                    <input
                      class="input"
                      type="time"
                      name="hour"
                      v-model="hour"
                      id="hour"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Type de match</label>
                  <div
                    class="control columns is-centered is-multiline is-mobile is-desktop is-tablet"
                  >
                    <label
                      v-for="typeItem in types"
                      :key="typeItem.id"
                      v-bind:class="{
                        isActive: this.type === typeItem.id,
                      }"
                      class="column is-6-tablet is-6-desktop is-6-mobile"
                    >
                      <input type="radio" v-model="type" :value="typeItem.id" />
                      <div class="card">
                        <div class="card-content">
                          <div class="content">
                            {{ typeItem.icon }} {{ typeItem.name }}
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Domicile</label>
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="is_home"
                        :value="true"
                        v-model="is_home"
                      />
                      Oui
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        name="is_home"
                        :value="false"
                        v-model="is_home"
                      />
                      Non
                    </label>
                  </div>
                </div>

                <div class="field column is-5" v-if="!is_home">
                  <label class="label">Lieu</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Lieu"
                      v-model="place"
                    />
                  </div>
                </div>

                <div class="field no-game-part">
                  <label class="label">Le match est terminé ?</label>
                  <div class="control">
                    <input
                      type="checkbox"
                      v-model="is_finish"
                      @change="changeIsFinish()"
                    />
                  </div>
                </div>

                <div v-if="is_finish">
                  <label class="label">Score : </label>
                  <div v-if="is_home" class="score-part">
                    <div class="control">
                      <label class="label">Herbignac</label>
                      <input class="input" type="number" v-model="sch_goals" />
                    </div>
                    -
                    <div class="control">
                      <label class="label">{{ opponent }}</label>
                      <input
                        class="input"
                        type="number"
                        v-model="opponent_goals"
                      />
                    </div>
                  </div>

                  <div v-if="!is_home" class="score-part">
                    <div class="control">
                      <label class="label">{{ opponent }}</label>
                      <input
                        class="input"
                        type="number"
                        v-model="opponent_goals"
                      />
                    </div>
                    -
                    <div class="control">
                      <label class="label">Herbignac</label>
                      <input class="input" type="number" v-model="sch_goals" />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Description / Message</label>
                  <div class="control">
                    <textarea
                      class="textarea"
                      placeholder="Message"
                      name="comment"
                      v-model="comment"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <button
            class="button is-danger"
            v-if="gameActif != null && !is_create"
            @click="lauchDelete(id)"
          >
            Supprimer
          </button>
          <button class="button is-success" v-if="is_create" @click="add()">
            Confirmer
          </button>
          <button
            class="button is-warning"
            v-if="!is_create"
            @click="update(id)"
          >
            Confirmer
          </button>

          <button class="button" @click="close">Annuler</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="gameActif.team == null">
            Supprimer un match
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer ce match ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="close">Annuler</button>
          <button
            class="button is-danger"
            v-if="gameActif != null"
            @click="deleteGame(gameActif)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: function () {
    return {
      teams: [],
      types: [],
      games: [],
      displayGames: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      gameActif: {},
      currentMatch: null,
      id: null,
      date: null,
      place: null,
      opponent: null,
      sch_goals: null,
      opponent_goals: null,
      team: null,
      type: null,
      hour: null,
      comment: null,
      is_home: true,
      is_create: false,
      is_finish: false,
      is_win: false,
      is_lose: false,
      is_draw: false,
      error_messsage: null,
      is_failure: false,
      displayOnlyCurrent: true,
    };
  },
  methods: {
    initializeData() {
      (this.isActiveModalUpdate = false),
        (this.isActiveModalDelete = false),
        (this.gameActif = {}),
        (this.currentMatch = null),
        (this.id = null),
        (this.date = null),
        (this.place = null),
        (this.opponent = null),
        (this.sch_goals = null),
        (this.opponent_goals = null),
        (this.team = null),
        (this.type = null),
        (this.hour = null),
        (this.comment = null),
        (this.is_home = true),
        (this.is_create = false),
        (this.is_finish = false),
        (this.is_win = false),
        (this.is_lose = false),
        (this.error_messsage = null),
        (this.is_failure = false),
        (this.is_draw = false),
        (this.displayOnlyCurrent = true);
      this.filterGames();
    },
    close() {
      this.initializeData();
    },
    changeDate(event) {
      this.teams = {};
      this.team = null;
      const date = event.target.value;
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("teams/withoutForGames/" + date)
        .then((response) => {
          this.teams = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeIsFinish() {
      if (!this.is_finish) {
        this.sch_goals = 0;
        this.opponent_goals = 0;
      }
    },
    checkRequired() {
      if (this.date == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner une date.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.opponent == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseigner un adversaire.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.team == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez sélectionnez une équipe d'Herbignac.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.type == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez sélectionnez un type de match.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.hour == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseignez l'heure du match.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      return true;
    },

    //partie ajout
    lauchCreate() {
      this.isActiveModalUpdate = true;
      this.is_create = true;
    },
    add() {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        date: this.date,
        place: this.place,
        opponent: this.opponent,
        sch_goals: this.sch_goals,
        opponent_goals: this.opponent_goals,
        team: this.team,
        type: this.type,
        comment: this.comment,
        is_home: this.is_home,
        hour: this.hour,
        is_finish: this.is_finish,
        is_win: null,
        is_lose: null,
        is_draw: null,
      };

      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .post("games", formData)
        .then((response) => {
          this.games.push(response.data);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //partie modification
    lauchUpdate(id) {
      var proxy = { ...this.games.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      if (target_copy.is_home) {
        this.currentMatch =
          target_copy.team.name + " vs " + target_copy.opponent;
      } else {
        this.currentMatch =
          target_copy.opponent + " vs " + target_copy.team.name;
      }
      (this.isActiveModalUpdate = true),
        (this.id = target_copy.id),
        (this.date = target_copy.date),
        (this.place = target_copy.place),
        (this.opponent = target_copy.opponent),
        (this.sch_goals = target_copy.sch_goals),
        (this.opponent_goals = target_copy.opponent_goals),
        (this.team = target_copy.team.id),
        (this.type = target_copy.type.id),
        (this.hour = target_copy.hour),
        (this.comment = target_copy.comment),
        (this.is_home = target_copy.is_home),
        (this.is_finish = target_copy.is_finish),
        (this.is_win = target_copy.is_win),
        (this.is_lose = target_copy.is_lose),
        (this.is_draw = target_copy.is_draw),
        (this.is_create = false);
    },
    update(id) {
      if (!this.checkRequired()) {
        return;
      }

      const formData = {
        date: this.date,
        place: this.place,
        opponent: this.opponent,
        sch_goals: this.sch_goals,
        opponent_goals: this.opponent_goals,
        team: this.team,
        type: this.type,
        comment: this.comment,
        is_home: this.is_home,
        hour: this.hour,
        is_finish: this.is_finish,
        is_win: null,
        is_lose: null,
        is_draw: null,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .put("games/" + id, formData)
        .then((response) => {
          const index = this.games.findIndex((obj) => obj.id == id);
          this.games[index] = response.data;
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //partie suppression
    lauchDelete(id) {
      this.is_create = false;

      var proxy = { ...this.games.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.gameActif = target_copy;
      this.isActiveModalDelete = true;
    },
    deleteGame(game) {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .delete("games/" + game.id)
        .then(() => {
          const index = this.games.indexOf(game);
          this.games.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterGames() {
      if (this.displayOnlyCurrent == true) {
        this.displayGames = this.games.filter((g) => g.is_finish != true);
      } else {
        this.displayGames = this.games;
      }
    },

    //partie modification du score

    changeScore() {},
    dateFormat(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      var newDate = new Date(date);
      newDate = newDate.toLocaleDateString("fr-FR", options);
      return newDate;
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("game-types")
      .then((response) => {
        this.types = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("teams")
      .then((response) => {
        this.teams = response.data;
        if (this.teams.length == 1) {
          this.team = this.teams[0];
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("games")
      .then((response) => {
        this.games = response.data;
        this.displayGames = this.games.filter((g) => g.is_finish != true);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.match-header {
  display: flex;

  button {
    margin-right: 10px;
  }

  .field {
    display: flex;
    text-align: center;
    flex-direction: column;
  }
}

.no-game-part {
  display: flex;
  justify-content: space-between;
}
.score-part {
  display: flex;
  text-align: center;
  .control {
    input {
      width: 50%;
    }
  }
}
</style>
