<template>
  <div class="columns contact">
    <div class="column left is-three-fifths">
      <ContactFormulaireComponent />
    </div>
    <div class="column right">
      <ContactInformationsComponent />
    </div>
  </div>
</template>
<script>
import ContactFormulaireComponent from "@/components/contact/ContactFormulaireComponent.vue";
import ContactInformationsComponent from "../components/contact/ContactInformationsComponent.vue";
export default {
  components: { ContactFormulaireComponent, ContactInformationsComponent },
};
</script>
<style lang="scss">
.contact {
  height: 80%;
  width: 100%;
  margin-top: 100px;

  .right {
    background-color: #e75913;
  }
}
.contact:not(:last-child) {
  margin: 50px 0 0 0;
}
</style>
