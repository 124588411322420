<template>
  <div class="actualite">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="title">Les actualités</h3>
          </div>
        </div>
      </section>
    </div>

    <div class="filtre-part">
      <label class="label filtreLabel">Filtrer par :</label>
      <div class="filtres">
        <div class="select select-type">
          <select @change="changeType">
            <option value="0">Type d'articles</option>
            <option
              v-for="article_type in types"
              :key="article_type.id"
              :value="article_type.id"
            >
              {{ article_type.name }}
            </option>
          </select>
        </div>
        <div class="select">
          <select @change="changeTag">
            <option>Tag</option>
            <option v-for="tag in tags" :key="tag.id" :value="tag.id">
              {{ tag.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="actualite-body">
      <div class="columns is-multiline is-mobile">
        <div
          class="column is-one-quarter-desktop is-half-mobile is-one-quarter-tablet"
          v-for="article in articlesToDislay"
          :key="article.id"
        >
          <ArticleComponent :article="article" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArticleComponent from "@/components/communs/ArticleComponent.vue";
import axios from "axios";

export default {
  components: { ArticleComponent },
  data: function () {
    return {
      articles: [],
      articlesToDislay: [],
      tags: [],
      types: [],
    };
  },
  methods: {
    changeType(e) {
      let type = e.target.options.selectedIndex;
      if (type == 0) {
        this.articlesToDislay = this.articles;
      } else {
        this.articlesToDislay = this.articles;
        this.articlesToDislay = this.articlesToDislay.filter(
          (article) => article.type.id == type
        );
      }
    },
    changeTag(e) {
      let tag = e.target.options.selectedIndex;
      if (tag == 0) {
        this.articlesToDislay = this.articles;
      } else {
        this.articlesToDislay = this.articles;
        this.articlesToDislay = this.articlesToDislay.filter((article) => {
          let tagsId = article.tags.map((t) => t.id);
          return tagsId.includes(tag);
        });
      }
    },
  },
  beforeMount() {
    axios
      .get("public/articles")
      .then((response) => {
        this.articles = response.data;
        this.articlesToDislay = this.articles;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("public/article_types")
      .then((response) => {
        this.types = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("public/tags")
      .then((response) => {
        this.tags = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.actualite {
  .hero {
    text-align: center;
    h2 {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/actualite.jpg");
    }
    .title {
      color: #ffffff;
    }
  }
  .filtre-part {
    .filtreLabel {
      margin-left: 1%;
    }

    .filtres {
      height: 50px;
      width: 270px;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;
      z-index: 2;
      position: relative;
      margin: 10px auto 0 2%;
      padding: 10px;
      display: flex;
      align-items: center;
      .select-type {
        margin-right: 10px;
      }

      .select {
        color: white;
        select {
          background-color: #e75913;
          color: white;
        }
      }
    }
  }

  .actualite-body {
    margin-top: 10px;
    margin-bottom: 20px;
    margin: 10px 10px 20px 10px;
    .pagination {
      .pagination-list {
        .is-current {
          background-color: #e75913;
          border: #e75913;
        }
      }
    }
  }
}
</style>
