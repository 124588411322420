<template>
  <div>
    <div class="home-article card">
      <router-link :to="{ name: 'details', params: { id: article.id } }"
        ><div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">{{ article.title }}</p>
              <p class="subtitle is-6" datetime="2016-1-1">
                {{ dateFormat(article.date) }}
              </p>
            </div>
          </div>

          <div class="tags">
            <span class="tag" v-for="tag in article.tags" :key="tag.id">
              {{ tag.name }}
            </span>
          </div>
        </div></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["article"],
  data() {
    return {
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
      isActiveModaleArticle: false,
    };
  },
  methods: {
    dateFormat(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var newDate = new Date(date);
      newDate = newDate.toLocaleDateString("fr-FR", options);
      return newDate;
    },
  },
};
</script>

<style lang="scss">
.home-article {
  width: 90%;
  .tag {
    background-color: #e75913;
    color: white;
    //margin-right: 15px;
  }
}
</style>
