import { createStore } from "vuex";

export default createStore({
  state: {
    token: null,
    isAuthenticated: false,
    abilities: null,
  },
  getters: {},
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("token") != null) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        (state.token = null),
          (state.isAuthenticated = false),
          (state.abilities = null);
      }
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      (state.token = null),
        (state.isAuthenticated = false),
        (state.abilities = null);
    },
  },
  actions: {},
  modules: {},
});
