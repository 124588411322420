<template>
  <div class="partenaire-item-container">
    <div class="card partenaire-item" v-on:click="launch">
      <div class="card-header">
        <p class="card-header-title">{{ partenaire.name }}</p>
      </div>
      <div class="card-content">
        <div class="card-image">
          <figure class="image is-3by3" v-if="partenaire.picture">
            <img
              :src="baseURL + partenaire.picture"
              alt="Logo de notre partenaire"
            />
          </figure>
          <figure class="image is-3by3" v-if="!partenaire.picture">
            <img
              src="@/assets/icons/no-photo.png"
              alt="Logo de notre partenaire"
            />
          </figure>
        </div>
      </div>
    </div>
    <div
      class="modal modal-partenaire"
      v-bind:class="{ 'is-active': isActiveModalePartenaire }"
    >
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ partenaire.name }}</p>
          <button class="delete" aria-label="close" v-on:click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="card-image">
            <figure class="image image is-128x128" v-if="partenaire.picture">
              <img :src="baseURL + partenaire.picture" alt="logo partenaire" />
            </figure>
            <figure class="image image is-128x128" v-if="!partenaire.picture">
              <img src="@/assets/partenaire/partenaire.png" alt="pas de logo" />
            </figure>
          </div>
          <div class="columns is-multiline">
            <div class="column is-half">
              <a
                :href="urlMap"
                target="_blanck"
                class="box"
                v-if="partenaire.address"
              >
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img
                        src="@/assets/icons/map-placeholder.png"
                        alt="home"
                      />
                    </span>
                  </span>
                </div>
                <div class="columns is-multiline adresse-part">
                  <div class="column is-half codePostale">
                    <span>{{ partenaire.zip }}</span>
                  </div>
                  <div class="column is-half city">
                    <span>{{ partenaire.city }}</span>
                  </div>
                  <div class="column is-full adresse">
                    <span>{{ partenaire.address }}</span>
                  </div>
                </div>
              </a>
              <div class="box" v-if="!partenaire.address">
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img src="@/assets/icons/no-location.png" alt="home" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <a
                :href="`mailto:${partenaire.email}`"
                target="_blanck"
                class="box"
                v-if="partenaire.email"
              >
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img src="@/assets/icons/mail.svg" alt="email" />
                    </span>
                  </span>
                </div>
                <div class="columns is-multiline mail-part">
                  <div class="column is-full">
                    <span>{{ partenaire.email }}</span>
                  </div>
                </div>
              </a>
              <div class="box" v-if="!partenaire.email">
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img src="@/assets/icons/forbidden.png" alt="no email" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <a
                :href="`tel:${partenaire.phone}`"
                target="_blanck"
                class="box"
                v-if="partenaire.phone"
              >
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img src="@/assets/icons/phone.svg" alt="phone" />
                    </span>
                  </span>
                </div>
                <div class="columns is-multiline phone-part">
                  <div class="column is-full">
                    <span>{{ partenaire.phone }}</span>
                  </div>
                </div>
              </a>
              <div class="box" v-if="!partenaire.phone">
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img src="@/assets/icons/no-call.png" alt="phone" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <a
                :href="`${partenaire.website}`"
                target="_blanck"
                class="box"
                v-if="partenaire.website"
              >
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img src="@/assets/icons/internet.png" alt="website" />
                    </span>
                  </span>
                </div>
                <div class="columns is-multiline phone-part">
                  <div class="column is-full">
                    <span>{{ partenaire.name }}</span>
                  </div>
                </div>
              </a>
              <div v-if="!partenaire.website">
                <div class="icon-part">
                  <span class="icon-text">
                    <span class="icon">
                      <img src="@/assets/icons/browser.png" alt="website" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["partenaire"],
  data() {
    return {
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
      isActiveModalePartenaire: false,
      urlMap:
        "https://www.google.com/maps/search/?api=1&query=" +
        this.partenaire.address +
        "+" +
        this.partenaire.zip +
        "+" +
        this.partenaire.city,
    };
  },
  methods: {
    launch: function () {
      this.isActiveModalePartenaire = true;
    },
    close: function () {
      this.isActiveModalePartenaire = false;
    },
  },
};
</script>
<style lang="scss">
.partenaire-item-container {
  height: 100%;

  .partenaire-item {
    height: 100%;

    .card-content {
      padding: 0;
    }

    &.partenaire-item:hover {
      cursor: pointer;
    }
  }
  .modal-partenaire {
    .modal-card {
      .modal-card-body {
        .card-image {
          text-align: -webkit-center;
        }
      }

      .box {
        height: 100%;

        .icon-part {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
        }
        .adresse-part {
          text-align: center;
          .column {
            line-height: 40%;
          }
        }
        .mail-part,
        .phone-part {
          text-align: center;
        }
      }
    }
  }
}
</style>
