<template>
  <div class="contact-informations">
    <form class="box">
      <h3 class="title">Informations</h3>

      <div class="mail-part">
        <div class="icon-part">
          <figure class="image is-48x48">
            <img src="@/assets/icons/mail.svg" alt="email" />
          </figure>
        </div>
        <div class="information-part">
          <strong>stcyrfootherbignac@hotmail.fr</strong>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.contact-informations {
  .box {
    color: #000000;
    background-color: #ffffff;
    text-align: center;

    .mail-part {
      display: flex;
      place-content: center;

      .icon-part {
        margin-right: 30px;
      }
    }

    .information-part {
      place-self: center;
    }
  }
}
</style>
