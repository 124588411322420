<template>
  <div>
    <div>
      <button class="button is-success" @click="lauchCreate()">
        Ajouter un article
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Titre</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="article in articles" :key="article.id">
          <td>{{ article.title }}</td>
          <td>
            {{ article.type.name }}
          </td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(article.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone de crayon"
                />
              </div>
            </button>

            <button class="button is-danger" @click="lauchDelete(article.id)">
              <div class="icon">
                <img
                  src="@/assets/icons/trash-can-solid.svg"
                  alt="icone de poubelle"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="actuTitle != null">
            {{ actuTitle }}
          </p>
          <p class="modal-card-title" v-if="actuTitle == null">
            Ajouter un article
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-12-mobile is-12-tablet is-12-desktop">
              <form @submit.prevent="submitForm" class="box">
                <div class="field">
                  <label class="label">Type </label>
                  <div
                    class="control types columns is-centered is-multiline is-mobile is-desktop is-tablet"
                  >
                    <label
                      v-for="typeItem in types"
                      :key="typeItem.id"
                      v-bind:class="{
                        isActive: typeItem.id === typeId,
                      }"
                      class="column is-6-tablet is-6-desktop is-6-mobile"
                    >
                      <input
                        type="radio"
                        v-model="typeId"
                        :value="typeItem.id"
                      />
                      <div class="card">
                        <div class="card-image">
                          <figure class="image">
                            <img
                              :src="baseURL + typeItem.picture"
                              :alt="typeItem.name"
                            />
                          </figure>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Titre</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Titre"
                      v-model="title"
                    />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Date</label>
                  <div class="control">
                    <input
                      class="input"
                      type="date"
                      placeholder="Titre"
                      v-model="date"
                    />
                  </div>
                </div>

                <div class="field no-game-part">
                  <label class="label">Sur plusieurs jours ?</label>
                  <div class="control">
                    <input type="checkbox" v-model="is_many_days" />
                  </div>
                </div>

                <div class="columns" v-if="is_many_days">
                  <div class="field column is-4">
                    <label class="label">Date début</label>
                    <div class="control">
                      <input
                        class="input"
                        type="date"
                        placeholder="Titre"
                        v-model="begin"
                      />
                    </div>
                  </div>
                  <div class="field column is-4">
                    <label class="label">Date Fin</label>
                    <div class="control">
                      <input
                        class="input"
                        type="date"
                        placeholder="Titre"
                        v-model="end"
                      />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Tags</label>
                  <div class="control tags columns is-multiline">
                    <label
                      class="checkbox column is-3"
                      v-for="tag in tags"
                      :key="tag.id"
                    >
                      <input type="checkbox" :name="tag.id" />
                      <div
                        class="box"
                        @click="updateTags(tag.id)"
                        v-bind:class="{
                          isSelected: this.currentTags.includes(tag.id),
                        }"
                      >
                        {{ tag.name }}
                      </div>
                    </label>
                  </div>
                </div>

                <div class="field" v-if="!is_create">
                  <label class="label">Ancienne photo</label>
                  <td v-if="picture != null">
                    <figure class="image">
                      <img :src="baseURL + picture" alt="Ancienne photo" />
                    </figure>
                  </td>
                  <td v-if="picture == null">❌</td>
                </div>

                <div class="field">
                  <label class="label">Photo</label>
                  <div class="file has-name is-boxed">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        @change="updateFile"
                        multiple
                      />
                      <span class="file-cta">
                        <span class="file-label">
                          Choisissez une ou plusieurs photos
                        </span>
                      </span>
                    </label>
                  </div>
                  <p class="help is-danger" v-if="!is_create">
                    En choisissant une nouvelle photo vous videz les photos
                    ajoutées lors de la création de cette actualité
                  </p>
                </div>
                <div v-if="tempUrl.length > 0">
                  <div class="field" v-for="temp in tempUrl" :key="temp">
                    <div class="column">
                      <label class="label">Nouvelle photo</label>
                      <figure class="image">
                        <img :src="temp" alt="Nouvelle photo" />
                      </figure>
                      <button
                        class="delete"
                        @click="deleteFile(temp)"
                        v-if="is_create || (!is_create && filesReload)"
                      ></button>
                      <button
                        class="up"
                        v-if="
                          (getIndexFile(temp) > 0 && is_create) ||
                          (getIndexFile(temp) > 0 && !is_create && filesReload)
                        "
                        @click="upFile(temp)"
                      >
                        <img src="@/assets/icons/up-arrow.png" alt="up" />
                      </button>
                      <button
                        class="down"
                        v-if="
                          (getIndexFile(temp) < tempUrl.length - 1 &&
                            is_create) ||
                          (getIndexFile(temp) < tempUrl.length - 1 &&
                            !is_create &&
                            filesReload)
                        "
                        @click="downFile(temp)"
                      >
                        <img
                          src="@/assets/icons/arrow-down-sign-to-navigate.png"
                          alt="down"
                        />
                      </button>
                      <span class="place">
                        {{ getIndexFile(temp) + 1 }} / {{ tempUrl.length }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Description / Message</label>
                  <div class="control">
                    <textarea
                      class="textarea"
                      placeholder="Message"
                      name="desription"
                      v-model="description"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <button class="button is-success" v-if="is_create" @click="add()">
            Ajouter cette actualité
          </button>
          <button
            class="button is-warning"
            v-if="!is_create"
            @click="update(id)"
          >
            Confirmer les modifications
          </button>

          <button class="button" @click="close">Annuler</button>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="articleActif.title != null">
            {{ articleActif.title }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer cette actualitée ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="close">Annuler</button>
          <button
            class="button is-danger"
            v-if="articleActif.title != null"
            @click="deleteArticle(articleActif)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
      files: [],
      types: [],
      tags: [],
      articles: [],
      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      articleActif: {},
      actuTitle: null,
      is_many_days: false,
      id: null,
      title: null,
      date: null,
      begin: null,
      end: null,
      type: null,
      typeId: null,
      description: null,
      picture: null,
      publication: null,
      is_create: false,
      tempUrl: [],
      role: null,
      is_failure: false,
      error_messsage: null,
      file: null,
      currentTags: [],
      picture_name: [],
      filesReload: false,
    };
  },
  methods: {
    //commun
    initializeData() {
      (this.actuTitle = null),
        (this.id = null),
        (this.title = null),
        (this.date = null),
        (this.begin = null),
        (this.end = null),
        (this.type = null),
        (this.currentTags = []),
        (this.description = null),
        (this.publication_date = null),
        (this.isActiveModalUpdate = false),
        (this.isActiveModalDelete = false),
        (this.typeId = null),
        (this.picture = null),
        (this.is_create = false),
        (this.tempUrl = []),
        (this.articleActif = {}),
        (this.files = []),
        (this.is_failure = false),
        (this.error_messsage = null),
        (this.picture_name = []),
        (this.filesReload = false),
        (this.is_create = false);
    },
    close() {
      this.initializeData();
    },
    updateTags(id) {
      if (this.currentTags.includes(id)) {
        const index = this.currentTags.indexOf(id);
        if (index > -1) {
          this.currentTags.splice(index, 1);
        }
      } else {
        this.currentTags.push(id);
      }
    },
    updateFile(e) {
      if (!this.is_create && !this.filesReload) {
        this.files = [];
        this.tempUrl = [];
        this.filesReload = true;
      }
      Array.from(e.target.files).forEach((file) => {
        if (file.size > 5000000) {
          this.is_failure = true;
          this.error_messsage = "Image trop volumineuse";
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
          return;
        }
        this.files.push(file);
        this.tempUrl.push(URL.createObjectURL(file));
      });
    },
    upFile(e) {
      const index = this.tempUrl.findIndex((obj) => obj == e);
      var element = this.files[index];
      this.tempUrl.splice(index, 1);
      this.files.splice(index, 1);
      this.tempUrl.splice(index - 1, 0, e);
      this.files.splice(index - 1, 0, element);
    },
    downFile(e) {
      const index = this.tempUrl.findIndex((obj) => obj == e);
      var element = this.files[index];
      this.tempUrl.splice(index, 1);
      this.files.splice(index, 1);
      this.tempUrl.splice(index + 1, 0, e);
      this.files.splice(index + 1, 0, element);
    },
    getIndexFile(value) {
      return this.tempUrl.findIndex((obj) => obj == value);
    },
    deleteFile(value) {
      const index = this.tempUrl.findIndex((obj) => obj == value);
      this.tempUrl.splice(index, 1);
      this.files.splice(index, 1);
    },
    checkRequired() {
      if (this.is_many_days) {
        if (this.begin == null || this.end == null) {
          this.is_failure = true;
          this.error_messsage =
            "Veuillez selectionnez une date de fin et une date de debut";
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
          return false;
        }
      }
      if (this.title == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseignez un titre.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      if (this.typeId == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez selectionnez un type.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }
      if (this.date == null) {
        this.is_failure = true;
        this.error_messsage = "Veuillez renseignez une date.";
        setTimeout(() => {
          this.is_failure = false;
          this.error_messsage = null;
        }, 3000);
        return false;
      }

      return true;
    },
    //partie ajout
    lauchCreate() {
      this.isActiveModalUpdate = true;
      this.is_create = true;
    },
    add() {
      if (!this.checkRequired()) {
        return;
      }
      const formDataPicture = new FormData();

      if (this.files.length > 0) {
        var data = 0;
        this.files.forEach((file) => {
          formDataPicture.append("picture", file);
          axios
            .create({
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .post("articles/upload", formDataPicture)
            .then((response) => {
              this.picture_name.push(response.data);
              data += 1;

              if (data == this.files.length) {
                const formData = {
                  title: this.title,
                  date: this.date,
                  begin: this.begin,
                  end: this.end,
                  type: this.typeId,
                  description: this.description,
                  picture: this.picture_name,
                  tags: this.currentTags,
                };

                axios
                  .create({
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  })
                  .post("articles", formData)
                  .then((response) => {
                    this.articles.push(response.data);
                    this.initializeData();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      } else {
        const formData = {
          title: this.title,
          date: this.date,
          begin: this.begin,
          end: this.end,
          type: this.typeId,
          description: this.description,
          picture: this.picture_name,
          tags: this.currentTags,
        };

        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .post("articles", formData)
          .then((response) => {
            this.articles.push(response.data);
            this.initializeData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    //partie modificatioon
    lauchUpdate(id) {
      var proxy = { ...this.articles.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      (this.actuTitle = target_copy.title),
        (this.id = target_copy.id),
        (this.title = target_copy.title),
        (this.type = target_copy.type),
        (this.date = target_copy.date),
        (this.begin = target_copy.begin),
        (this.typeId = target_copy.type.id),
        (this.end = target_copy.end),
        (this.description = target_copy.description);

      Array.from(target_copy.pictures).forEach((file) => {
        this.tempUrl.push(this.baseURL + file.name);
      });

      target_copy.tags.forEach((tag) => {
        this.currentTags.push(tag.id);
      });

      this.isActiveModalUpdate = true;
      this.is_create = false;
    },
    update(id) {
      if (!this.checkRequired()) {
        return;
      }
      const formDataPicture = new FormData();
      if (this.files.length > 0) {
        var data = 0;
        this.files.forEach((file) => {
          formDataPicture.append("picture", file);
          axios
            .create({
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .post("articles/upload", formDataPicture)
            .then((response) => {
              this.picture_name.push(response.data);
              data += 1;
              if (data == this.files.length) {
                const formData = {
                  title: this.title,
                  date: this.date,
                  begin: this.begin,
                  end: this.end,
                  type: this.typeId,
                  description: this.description,
                  picture: this.picture_name,
                  tags: this.currentTags,
                };

                axios
                  .create({
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  })
                  .put("articles/" + id, formData)
                  .then((response) => {
                    const index = this.articles.findIndex(
                      (obj) => obj.id == id
                    );
                    this.articles[index] = response.data;
                    this.initializeData();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      } else {
        const formData = {
          title: this.title,
          date: this.date,
          begin: this.begin,
          end: this.end,
          type: this.typeId,
          description: this.description,
          picture: this.picture_name,
          tags: this.currentTags,
        };

        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .put("articles/" + id, formData)
          .then((response) => {
            const index = this.articles.findIndex((obj) => obj.id == id);
            this.articles[index] = response.data;
            this.initializeData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    //partie suppression
    lauchDelete(id) {
      var proxy = { ...this.articles.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.articleActif = target_copy;
      this.isActiveModalDelete = true;
    },

    deleteArticle(article) {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .delete("articles/" + article.id)
        .then(() => {
          const index = this.articles.indexOf(article);
          this.articles.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("article-types")
      .then((response) => {
        this.types = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("articles")
      .then((response) => {
        this.articles = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("tags")
      .then((response) => {
        this.tags = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.types {
  label {
    input {
      display: none;
    }
  }
  .isActive {
    border: 3px solid #e75913;
    border-radius: 30px;
  }
}

.tags {
  label {
    input {
      display: none;
    }
  }
  .isSelected {
    background-color: #e75913;
    color: #ffffff;
  }
}

.up,
.down,
.delete {
  border: none;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-top: 10px;
}

.up,
.down {
  margin-left: 10px;
}

.place {
  float: right;
  margin-top: 10px;
}

.update-button {
  margin-right: 15px;
  margin-bottom: 10px;
}
</style>
